import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import RGL, { WidthProvider } from "react-grid-layout";
import { useDispatch, useSelector } from "react-redux";
import Langs from "../../app/lang/langs";
import {
  checkIfEqual,
  checkPermission,
  formatDate,
  getUserPreferences,
  isOwner,
  setUserPreferences,
  isAdmin
} from "../../app/utils/helpers";
import addIcon from "../../assets/images/add.png";
import addIconFocus from "../../assets/images/addFocus.png";
import deleteIcon from "../../assets/images/delete.png";
import deleteIconFocus from "../../assets/images/delete-orange.png";
import xIcon from "../../assets/images/reject.png";
import xIconFocus from "../../assets/images/rejectDanger.png";
import checkIcon from "../../assets/images/check.png";
import checkIconFocus from "../../assets/images/checkSuccess.png"
import editIcon from "../../assets/images/edit.png";
import editIconFocus from "../../assets/images/editFocus.png";
import ImageButton from "../../components/buttons/ImageButton";
import { NumberInput } from "../../components/inputs/NumberInput";
import { SelectInput } from "../../components/inputs/Select";
import { ShiftCell } from "../../components/scheduler/cells/shiftCell.js";
import { ShiftFormModal } from "../../components/scheduler/forms/ShiftFormModal.js";
import { generateSampleLeave } from "../../components/scheduler/leave/leaveSampleSlice";
import { generateSampleShift } from "../../components/scheduler/shifts/sampleShiftsSlice";
import { hideFormPopup, setShowFormPopup } from "../planning/planningSlice";
import {
  getSettings,
  setLocalSettings,
  updateSettings,
  setChangedSettingsList,
  updateTeamSettings
} from "./settingsSlice";
import styles from "./Settings.module.css";
import {
  getHolidays,
  setLanguage,
  setSelectedYear,
} from "./settingsSlice";
import { ThemeButton } from "../../components/buttons/ThemeButton";
import { config } from "../../app/utils/config.js";
import { updateAllTeamsSettings } from "../../components/teams/teamsSlice.js";
import {
  addEvaluationField,
  deleteEvaluationField,
  setAddFieldResponse,
  setDeleteFieldResponse,
  reorderEvaluationsFields,
  reorderEvaluationTypes,
  setTypeModalVisibilty,
  setFieldModalVisibilty,
  setOnEditField,
  getEvaluationFields,
  getEvaluationsTypes,
  setOnEditType,
  setModifyTypeResponse
} from "../../components/evaluations/evaluationsSlice.js";
import { showErrorToast, showSuccessToast } from "../../app/utils/helpers";
import { Dialog } from "primereact/dialog";
import { DynamicButton } from "../../components/buttons/DynamicButton.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import EvaluationTypeModal from "./Modals/EvaluationTypeModal.js";
import EvaluationFieldModal from "./Modals/EvaluationFieldModal.js";

const ReactGridLayout = WidthProvider(RGL);
const pjson = require('../../../package.json');

export function SettingsView() {

  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const localSettingsList = useSelector(
    (state) => state.settings.localSettingsList
  )
  const updatedSettingsParams = useSelector((state) => state.settings.updatedSettingsParams)
  // const headerAction = useSelector((state) => state.common.headerAction)
  const userId = useSelector((state) => state.login.userId)
  const sampleShifts = useSelector((state) => state.sampleShifts.list)
  const sampleLeaves = useSelector((state) => state.sampleLeaves.list)
  const showFormPopup = useSelector((state) => state.planning.showFormPopup)
  const userRoleId = useSelector((state) => state.login.userRoleId)
  const permissions = useSelector((state) => state.login.permissions)
  const teams = useSelector((state) => state.teams.list)
  const allEvaluationFields = useSelector((state) => state.evaluations.fieldsIgnoreActive)
  const deleteEvaluationFieldResponse = useSelector((state) => state.evaluations.deleteFieldResponse)
  const addEvaluationFieldResponse = useSelector((state) => state.evaluations.addFieldResponse)
  const evaluationsTypes = useSelector((state) => state.evaluations.typesIgnoreActive)
  const modifyTypeResponse = useSelector((state) => state.evaluations.modifyTypeResponse)
  const isMobile = useSelector((state) => state.settings.isMobile)

  const [menuList, setMenuList] = useState([])

  const [selectedMenu, setSelectedMenu] = useState(0)

  const [lang, setLang] = useState(settings.lang)
  const [selectedTeam, setSelectedTeam] = useState()
  const [newTeamSettings, setNewTeamSettings] = useState()
  const [onAddEvaluationRowVisibility, setOnAddEvaluationRowVisibility] = useState(false)
  const [evaluationFieldTitle, setEvaluationFieldTitle] = useState("")
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false)
  const [deleteFieldId, setDeleteFieldId] = useState(null)
  const [orderedFields, setOrderedFields] = useState()
  const [orederedTypes, setOrderedTypes] = useState(evaluationsTypes)
  const [selectedEvaluationTypeLocal, setSelectedEvaluationTypeLocal] = useState()

  const dtFields = useRef(null)
  const dtTypes = useRef(null)


  const languages = [
    { value: "en", label: "English" },
    { value: "fr", label: "Français" },
  ]

  useEffect(() => {
    if (deleteEvaluationFieldResponse != null) {
      if (deleteEvaluationFieldResponse.trim().toLowerCase().includes("successfully")) {
        showSuccessToast("Success!")
      } else {
        showErrorToast(deleteEvaluationFieldResponse)
      }
      if (selectedEvaluationTypeLocal) {
        dispatch(getEvaluationFields({ idType: selectedEvaluationTypeLocal.id }))
      }
      dispatch(setDeleteFieldResponse(null))
    }
  }, [deleteEvaluationFieldResponse])

  useEffect(() => {
    if (addEvaluationFieldResponse != null) {
      if (addEvaluationFieldResponse.trim().toLowerCase().includes("successfully")) {
        showSuccessToast("Success!")
      } else {
        showErrorToast(addEvaluationFieldResponse)
      }
      if (selectedEvaluationTypeLocal) {
        dispatch(getEvaluationFields({ idType: selectedEvaluationTypeLocal.id }))
      }
      dispatch(setAddFieldResponse(null))
    }
  }, [addEvaluationFieldResponse])

  useEffect(() => {
    if (modifyTypeResponse != null) {
      if (modifyTypeResponse.trim().toLowerCase().includes("successfully")) {
        showSuccessToast("Success!")
      } else {
        showErrorToast(modifyTypeResponse)
      }
      dispatch(getEvaluationsTypes())
      dispatch(setModifyTypeResponse(null))
    }
  }, [modifyTypeResponse])

  useEffect(() => {
    let menuList = [];

    if (checkPermission(config.scopes.settings, config.permissionTypes.edit)) {
      menuList = [
        "Réglages généraux",
        "Réglages clients",
        "Modèles d'horaires",
        "Jours fériés",
      ]
    } else if (checkPermission(config.scopes.shifts, config.permissionTypes.edit)) {
      menuList = [
        "Réglages généraux",
        "Réglages clients",
        "Jours fériés",
      ]
    } else {
      menuList = [
        "Réglages généraux",
        "Jours fériés",
      ]
    }

    if (isOwner(userRoleId)) {
      if (isMobile == 1) {
        menuList.push("Questionnaire d'évaluations")
      }
    }

    setMenuList(menuList)
    dispatch(getSettings())
  }, [dispatch, userRoleId, permissions])


  useEffect(() => {
    if (menuList.includes("Réglages clients") && !selectedTeam && teams[0]) {
      setSelectedTeam(teams[0])
      setNewTeamSettings(teams[0].settings)
    }
  }, [dispatch, teams])

  useEffect(() => {
    if (selectedMenu === menuList.indexOf("Questionnaire d'évaluations")) {
      dispatch(getEvaluationsTypes())
      if (selectedEvaluationTypeLocal) {
        dispatch(getEvaluationFields({ idType: selectedEvaluationTypeLocal.id }))
      }
    }
  }, [selectedMenu, selectedEvaluationTypeLocal])

  useEffect(() => {
    if (selectedEvaluationTypeLocal)
      setOrderedFields(allEvaluationFields)
  }, [allEvaluationFields])

  useEffect(() => {
    setOrderedTypes(evaluationsTypes)
  }, [evaluationsTypes])



  const handleLanguageChange = (selectedOption) => {
    setLang(selectedOption.value)
    dispatch(setLanguage(selectedOption.value))
  }

  const handleTeamChange = (selectedOption) => {
    setSelectedTeam(selectedOption)
    setNewTeamSettings(selectedOption.settings)
  }

  const handleParameterChange = (event) => {
    // let parameters = Object.assign({}, params);
    // parameters[event.target.name] = event.target.value;
    // setParams(parameters);
    // dispatch(setParameters(parameters));
    for (const key in localSettingsList) {
      if (localSettingsList[key].id == event.target.id) {
        dispatch(setLocalSettings({ value: event.target.value, key: key }))
        // localSettings[key].value = event.target.value
      }
    }
    dispatch(setChangedSettingsList({
      id: parseInt(event.target.id),
      value: parseFloat(event.target.value)
    }))
  }

  const handleTeamParameterChange = (event) => {
    let settings = newTeamSettings || selectedTeam.settings
    let newSettings = settings.map(s => { return { ...s } })
    for (const key in newSettings) {
      if (newSettings[key].id == event.target.id) {
        newSettings[key].value = event.target.value
      }
    }
    setNewTeamSettings(newSettings)
  }

  const handleTeamParameterSave = () => {
    let params = { idTeam: selectedTeam.value, fields: [] }
    newTeamSettings.map(s => {
      params.fields.push({
        id: s.id,
        value: s.type == 'number' ? Number(s.value) : s.value
      })
    })
    dispatch(updateTeamSettings(params))
    dispatch(updateAllTeamsSettings({ id: selectedTeam.id, settings: newTeamSettings }))
    setSelectedTeam({ ...selectedTeam, settings: newTeamSettings })
  }

  const handleAddSampleShift = (event) => {
    let heureDebut = 9, heureFin = 17
    settings.settingsList.map(item => {
      if (item.label === "Heure début de journée") heureDebut = item.settingValue
      if (item.label === "Heure fin de journée") heureFin = item.settingValue
    })
    dispatch(
      setShowFormPopup({
        type: "ADD_SAMPLE_SHIFT",
        data: generateSampleShift(heureDebut, heureFin),
      })
    )
  }

  const handleAddSampleLeave = (event) => {
    let heureDebut = 9, heureFin = 17
    settings.settingsList.map(item => {
      if (item.label === "Heure début de journée") heureDebut = item.settingValue
      if (item.label === "Heure fin de journée") heureFin = item.settingValue
    })
    dispatch(
      setShowFormPopup({
        type: "ADD_SAMPLE_LEAVE",
        data: generateSampleLeave(heureDebut, heureFin),
      })
    )
  }

  function generateYearsOptions() {
    let options = []
    let now = new Date()
    let fromYear = now.getFullYear()
    for (let i = 2022; i < fromYear + 5; i++) {
      now.setFullYear(i)
      options.push({
        value: now.getFullYear(),
        label: now.getFullYear(),
      })
    }
    return options
  }

  function constructLayoutForSampleShifts() {
    let layout = []
    let shiftsArray = [].concat(sampleShifts)
    let shiftsUserOrder = getUserPreferences(userId, "SHIFTS_SAMPLES_ORDER")

    if (shiftsUserOrder) {
      shiftsArray = shiftsArray.sort((a, b) => {
        return shiftsUserOrder.indexOf(`${a["id"]}`) - shiftsUserOrder.indexOf(`${b["id"]}`)
      })
    }
    for (let i = 0; i < shiftsArray.length; i++) {
      let shift = shiftsArray[i]
      if (shift.id) {
        layout.push({
          i: shift["id"],
          x: i,
          y: 0,
          w: 1,
          h: 1,
          maxH: 1,
          minH: 1,
          data: shift,
        })
      }
    }
    return layout
  }

  function constructLayoutForSampleLeaves() {
    let layout = []
    for (let i = 0; i < sampleLeaves.length; i++) {
      let leave = sampleLeaves[i];
      layout.push({
        i: leave["id"],
        x: i,
        y: 0,
        w: 1,
        h: 1,
        maxH: 1,
        minH: 1,
        data: leave,
      })
    }
    return layout
  }

  const handleYearChange = (option) => {
    dispatch(setSelectedYear(option.value));
    if (!settings.holidays[option.value]) dispatch(getHolidays(option.value))
  }

  const onLayoutChange = (layout) => {
    let newOrder = layout
      .sort((a, b) => a.y - b.y)
      .reduce((array, current) => {
        array.push(current["i"])
        return array
      }, [])
    setUserPreferences(userId, "SHIFTS_SAMPLES_ORDER", newOrder)
  }
  const handleAddEvaluationFieldVisibility = () => {
    setOnAddEvaluationRowVisibility(true);
  }
  const handleDeleteEvaluationField = (id) => {
    if (isAdmin(userRoleId) && deleteFieldId != null) {
      dispatch(deleteEvaluationField({ field_id: id }))
      setConfirmDeleteDialog(false);
      setDeleteFieldId(null)
    }
  }
  const handleAddEvaluationField = () => {
    if (isAdmin(userRoleId) && selectedEvaluationTypeLocal) {
      dispatch(addEvaluationField({ title: evaluationFieldTitle, idType: selectedEvaluationTypeLocal.id, rank: allEvaluationFields.length }));
      handleCancelAddField()
    }
  }

  const handleCancelAddField = () => {
    setEvaluationFieldTitle('')
    setOnAddEvaluationRowVisibility(false)
  }

  function renderHolidaysForSelectedYear() {
    return (
      <Col className={styles.itemsList + " p-2 ps-4 m-0"}>
        <Row className="pt-2 pe-2 m-0">
          <Col xs={6} className="p-0 m-0">
            <label>
              <Langs str="Jours férié" />
            </label>
          </Col>
          <Col xs={6} className="p-0 m-0">
            <label>
              <Langs str="Date" />
            </label>
          </Col>
        </Row>
        {settings.holidays[settings.selectedYear] &&
          settings.holidays[settings.selectedYear].map((holiday) => {
            let dateArray = holiday.date.split("-");
            let date = new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);
            return (
              <Row className={styles.itemLine + " pt-3 pe-2 m-0"}>
                <Col className="p-0 m-0" xs={6}>
                  <span><Langs str={holiday.title} /></span>
                </Col>
                <Col xs={6} className="p-0 m-0">
                  <span>{formatDate(date, "dddd DD MMMM YYYY")}</span>
                </Col>
              </Row>
            )
          })}
      </Col>
    )
  }

  function renderAddEvaluationRow() {
    if (onAddEvaluationRowVisibility) {
      return (
        <Col lg={12} className="row mx-auto py-3 border-bottom pe-4 ms-3 me-4" style={{ backgroundColor: "#d6f1d6" }}>
          <Col lg={6} className="ps-0 my-auto">
            <input
              className="form-control"
              value={evaluationFieldTitle}
              onChange={(e) => { setEvaluationFieldTitle(e.target.value) }}
            />
          </Col>
          <Col lg={6} className="d-flex justify-content-end align-items-center pe-4">
            {evaluationFieldTitle.trim() != '' &&
              <ImageButton
                image={checkIcon}
                imageFocus={checkIconFocus}
                onClick={() => { handleAddEvaluationField() }}
                tooltip={<Langs str="ADD" />}
                active={true}
                width={32}
              />}
            <ImageButton
              image={xIcon}
              imageFocus={xIconFocus}
              onClick={() => { handleCancelAddField() }}
              tooltip={<Langs str="CANCEL" />}
              active={true}
              width={22}
            />
          </Col>
        </Col>
      )
    }
  }

  const handleReorderFields = (e) => {
    const { value } = e;
    let reorderedFields = [...value]
    reorderedFields = reorderedFields.map((field, index) => ({
      ...field,
      rank: index
    }));
    const requestArray = reorderedFields.map(field => ({
      id: field.id,
      rank: field.rank
    }));
    dispatch(reorderEvaluationsFields(requestArray))
    setOrderedFields(reorderedFields);
  };

  const handleReorderTypes = (e) => {
    const { value } = e;
    let reorderedTypes = [...value]
    reorderedTypes = reorderedTypes.map((type, index) => ({
      ...type,
      rank: index
    }));
    const requestArray = reorderedTypes.map(type => ({
      id: type.id,
      rank: type.rank
    }));
    dispatch(reorderEvaluationTypes(requestArray))
    setOrderedTypes(reorderedTypes);
  };


  const handleEditField = (rowData) => {
    return (
      <div className="d-flex justify-content-end">
        <ImageButton
          image={editIcon}
          imageFocus={editIconFocus}
          onClick={() => { dispatch(setFieldModalVisibilty(true)); dispatch(setOnEditField(rowData.id)) }}
          tooltip={<Langs str="EDIT" />}
          active={true}
          width={30}
        />
        <ImageButton
          image={deleteIcon}
          imageFocus={deleteIconFocus}
          onClick={() => { setConfirmDeleteDialog(true); setDeleteFieldId(rowData.id) }}
          tooltip={<Langs str="Delete" />}
          active={true}
          width={30}
        />
      </div>
    )
  }
  const handleEditType = (rowData) => {
    return (
      <div className="d-flex justify-content-end">
        <ImageButton
          image={editIcon}
          imageFocus={editIconFocus}
          onClick={() => { dispatch(setTypeModalVisibilty(true)); dispatch(setOnEditType(rowData.id)) }}
          tooltip={<Langs str="EDIT" />}
          active={true}
          width={32}
        />
      </div>
    )
  }

  const rowClass = (rowData) => {
    if (rowData) {
      if (rowData.active) {
        return styles.rowShadeGreen
      }
      return styles.rowShadeRed
    }
  }
  const typeBody = (rowData) => {
    if (rowData) {
      return (
        <span>
          {rowData.title}
        </span>
      )
    }
  }

  const handleTypeRowClick = (event) => {
    if (Array.isArray(event.value)) {
      setSelectedEvaluationTypeLocal(event.value[0])
    } else {
      setSelectedEvaluationTypeLocal(event.value)
    }
  }

  function renderEvaluationTypes() {
    return (
      <div>
        <DataTable ref={dtTypes}
          value={orederedTypes}
          reorderableRows
          onRowReorder={(e) => handleReorderTypes(e)}
          dataKey="id"
          scrollable
          scrollHeight="calc(100vh - 185px)"
          sortMode="single"
          sortField="rank"
          selectionMode="single"
          rowClassName={rowClass}
          selection={selectedEvaluationTypeLocal}
          onSelectionChange={handleTypeRowClick}
        >
          <Column rowReorder style={{ width: '3rem' }} />
          <Column field="title" body={typeBody} header={<Langs str="TYPE" />}></Column>
          <Column field="id" body={handleEditType} alignFrozen="right" frozen={true}></Column>
        </DataTable>
      </div>
    )
  }

  function renderEvaluationFields() {
    return (
      <div>
        <DataTable ref={dtFields}
          value={orderedFields}
          reorderableRows
          onRowReorder={(e) => handleReorderFields(e)}
          dataKey="id"
          scrollable
          scrollHeight="calc(100vh - 300px)"
          sortMode="single"
          sortField="rank"
          rowClassName={rowClass}
        >
          <Column rowReorder style={{ width: '3rem' }} />
          <Column field="title" header={<Langs str="Question" />}></Column>
          <Column field="id" body={handleEditField} alignFrozen="right" frozen={true}></Column>
        </DataTable>
      </div>
    )
  }

  function renderConfirmDeleteDialog() {
    let field = allEvaluationFields.find(field => field.id === deleteFieldId);
    return (

      <Dialog draggable={false} header="Confirmation" visible={confirmDeleteDialog} style={{ width: '30vw' }} onHide={() => { setConfirmDeleteDialog(false); setDeleteFieldId(null) }}>
        <Row className="d-flex justify-content-center">
          <Col lg={12} className="my-4 d-flex justify-content-center">
            <Langs str="DELETE_QUESTION" />:
          </Col>
          <Col lg={12} className="mb-4 d-flex justify-content-center text-center">
            <b>{field && field.title}</b>
          </Col>
          <Col lg={12} className="d-flex justify-content-center text-danger text-center">
            &#x26A0; <Langs str='FIELD_DELETE_QUESTION_MESSAGE' />
          </Col>
          <Col lg={3} className="my-3 mt-4">
            <DynamicButton
              onClick={() => handleDeleteEvaluationField(deleteFieldId)}
              className=''
              title={<Langs str="DELETE" />}
              active={true}
              color="red"
            />
          </Col>
        </Row>
      </Dialog>
    )
  }

  const handleEvaluationTypeChange = (e) => {
    setSelectedEvaluationTypeLocal(e.value)
  }
  const handleMenuChange = (selectedList) => {
    setSelectedMenu(selectedList[0]);
  };
  const handleMenuChangeV2 = (e) => {
    setSelectedMenu(e.value);
  };
  let sampleShiftsLayout = constructLayoutForSampleShifts()
  let sampleLeavesLayout = constructLayoutForSampleLeaves()

  function renderMenuContent() {
    if (selectedMenu === menuList.indexOf("Réglages généraux")) {
      let filteredLocalSettings = localSettingsList.filter(s => s.id_role >= userRoleId)
      return <div>
        <Row className="m-0">
          <Col xl={1}>
          </Col>
          <Col xl={8} className="d-flex align-content-center">
            <div className={styles.menuContent}>
              <Row className="p-2">
                <Row>
                  <Col
                    md={6}
                    className="d-flex justify-content-start align-items-center p-2 ps-4">
                    <label className={styles.menuTitle}>
                      <Langs str="LANGUAGE" />
                    </label>
                  </Col>
                  <Col md={6} className="p-2 m-0">
                    <SelectInput
                      key={"lang_selector"}
                      value={lang}
                      onChange={handleLanguageChange}
                      options={languages} />
                  </Col>
                </Row>
              </Row>
            </div>
          </Col>
        </Row>
        {filteredLocalSettings.length && <Row className="m-0">
          <Col xl={1}>
          </Col>
          <Col xl={8} className="d-flex align-content-center">
            <div className={styles.menuContent + " mt-1"}>
              <Row className="p-2">
                {filteredLocalSettings.map((setting) => (
                  <Row key={`ITEM_${setting.id}`}>
                    <Col
                      xl={9}
                      className="d-flex justify-content-start align-items-center p-2 ps-4">
                      <label className={styles.menuTitle}>
                        <Langs str={setting.label} />
                      </label>
                    </Col>
                    <Col
                      xl={3}
                      className="d-flex justify-content-end align-items-center p-2 m-0">
                      <NumberInput
                        name={setting.label}
                        min={setting.min || 0}
                        id={setting.id}
                        data-key={setting.value}
                        // disableKeyboard = {true}
                        max={setting.max || 999}
                        onChange={handleParameterChange}
                        value={setting.settingValue}
                      />
                    </Col>
                  </Row>
                ))}
                {checkPermission(config.scopes.settings, config.permissionTypes.edit) && <Row>
                  <Col
                    xl={4}
                    className="d-flex justify-content-start align-items-center p-2 ps-4"
                  ></Col>
                  <Col
                    xl={4}
                    className="d-flex justify-content-center align-items-center p-2 m-0 mt-3">
                    {!checkIfEqual(settings.settingsList, localSettingsList) && <ThemeButton
                      title="SAVE"
                      active={true}
                      onClick={() => {
                        dispatch(updateSettings(updatedSettingsParams)).then(
                          (response) => dispatch(getSettings())
                        )
                      }}
                    ></ThemeButton>}
                  </Col>
                </Row>}
              </Row>
            </div>
          </Col>
        </Row>
        }
      </div>
    }
    else if (selectedMenu === menuList.indexOf("Réglages clients"))
      return <Row className="m-0">
        <Col xl={1}>
        </Col>
        <Col xl={8} className="d-flex align-content-center">
          <div className={styles.menuContent}>
            <Row className="p-2">
              <Row>
                <Col
                  md={4}
                  className="d-flex justify-content-start align-items-center p-2 ps-4">
                  <label className={styles.menuTitle}>
                    <Langs str="CLIENT" />
                  </label>
                </Col>
                <Col md={8} className="p-2 m-0">
                  <SelectInput
                    key={"team_selector"}
                    value={selectedTeam && selectedTeam.id}
                    onChange={handleTeamChange}
                    options={teams} />
                </Col>
              </Row>
              {newTeamSettings && newTeamSettings.map((setting) => (
                <Row key={`ITEM_${setting.id}`}>
                  <Col
                    xl={9}
                    className="d-flex justify-content-start align-items-center p-2 ps-4">
                    <label className={styles.menuTitle}>
                      <Langs str={setting.label} />
                    </label>
                  </Col>
                  <Col
                    xl={3}
                    className="d-flex justify-content-end align-items-center p-2 m-0">
                    <NumberInput
                      name={setting.label}
                      min={setting.min || 0}
                      id={setting.id}
                      data-key={setting.value}
                      // disableKeyboard = {true}
                      max={setting.max || 999}
                      onChange={handleTeamParameterChange}
                      value={setting.value}
                    />
                  </Col>
                </Row>
              ))}
              {checkPermission(config.scopes.shifts, config.permissionTypes.edit) && <Row>
                <Col
                  xl={4}
                  className="d-flex justify-content-start align-items-center p-2 ps-4"
                ></Col>
                <Col
                  xl={4}
                  className="d-flex justify-content-center align-items-center p-2 m-0 mt-3">
                  {newTeamSettings && JSON.stringify(newTeamSettings) != JSON.stringify(selectedTeam.settings) && <ThemeButton
                    title="SAVE"
                    active={true}
                    onClick={handleTeamParameterSave}
                  ></ThemeButton>}
                </Col>
              </Row>}
            </Row>
          </div>
        </Col>
      </Row>
    else if (selectedMenu === menuList.indexOf("Modèles d'horaires"))
      return <Row className="m-0">
        <Col xl={1}>
        </Col>
        <Col xl={8} className="d-flex align-content-center">
          <div className={styles.menuContent}>
            <Row className="p-2">
              <Col
                xl={6}
                className="d-flex justify-content-start align-items-center p-2 ps-4">
                <label className={styles.menuTitle}>
                  <Langs str={menuList[selectedMenu]} />
                </label>
              </Col>
              <Col
                xl={6}
                className="d-flex justify-content-end align-items-center p-2 pe-4">
                {isOwner(userRoleId) && <ImageButton
                  onClick={handleAddSampleShift}
                  image={addIcon}
                  imageFocus={addIconFocus}
                  active={true}
                  width={32}
                />}
              </Col>
              <Col>
                <ReactGridLayout
                  layout={sampleShiftsLayout}
                  onLayoutChange={onLayoutChange}
                  items={sampleShiftsLayout.length}
                  rowHeight={48}
                  cols={1}
                  isResizable={false}
                  isDraggable={true}
                  isBounded={true}>
                  {sampleShiftsLayout.map((item) => {
                    return (
                      <div key={item["i"]}>
                        <ShiftCell
                          data={item.data}
                          isInteractive={true}
                          key={`ShiftCell_${item["i"]}`} />
                      </div>
                    )
                  })}
                </ReactGridLayout>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    else if (selectedMenu === menuList.indexOf("Congés"))
      return <Row className="m-0">
        <Col xl={1}>
        </Col>
        <Col xl={8} className="d-flex align-content-center">
          <div className={styles.menuContent}>
            <Row className="p-2">
              <Col
                xl={6}
                className="d-flex justify-content-start align-items-center p-2 ps-4">
                <label className={styles.menuTitle}>
                  <Langs str={menuList[selectedMenu]} />
                </label>
              </Col>
              <Col
                xl={6}
                className="d-flex justify-content-end align-items-center p-2 pe-4">
                <ImageButton
                  onClick={handleAddSampleLeave}
                  image={addIcon}
                  imageFocus={addIconFocus}
                  active={true}
                  width={32}
                />
              </Col>
              <Col>
                <ReactGridLayout
                  layout={sampleLeavesLayout}
                  items={sampleLeavesLayout.length}
                  rowHeight={48}
                  cols={1}
                  isResizable={false}
                  isDraggable={true}
                  isBounded={true}>
                  {sampleLeavesLayout.map((item) => {
                    return (
                      <div key={item["i"]}>
                        <ShiftCell
                          data={item.data}
                          isInteractive={true}
                          key={`ShiftCell_${item["i"]}`} />
                      </div>
                    )
                  })}
                </ReactGridLayout>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    else if (selectedMenu === menuList.indexOf("Jours fériés"))
      return <Row className="m-0">
        <Col xl={1}>
        </Col>
        <Col xl={8} className="d-flex align-content-center">
          <div className={styles.menuContent}>
            <Row className="p-2">
              <Row>
                <Col
                  xl={6}
                  className="d-flex justify-content-start align-items-center p-2 ps-4">
                  <label className={styles.menuTitle}>
                    <Langs str={menuList[selectedMenu]} />
                  </label>
                </Col>
                <Col
                  xl={6}
                  className="d-flex justify-content-end align-items-center p-2 pe-4">
                  <SelectInput
                    value={settings.selectedYear}
                    onChange={handleYearChange}
                    options={generateYearsOptions()}
                  />
                </Col>
                {renderHolidaysForSelectedYear()}
              </Row>
            </Row>
          </div>
        </Col>
      </Row>
    else if (selectedMenu === menuList.indexOf("Questionnaire d'évaluations"))
      return <Row className="m-0">
        <Col xl={7}>
          <div className={styles.menuContent}>
            <Row className="p-2">
              <Col xl={6}
                className="d-flex justify-content-start align-items-center p-2 ps-4 mb-4 fw-bold">
                <label className={styles.menuTitle}>
                  <Langs str='TYPES' />
                </label>
              </Col>
              <Col
                xl={6}
                className="d-flex justify-content-end align-items-center p-2 pe-4 mb-4">
                <ImageButton
                  image={addIcon}
                  imageFocus={addIconFocus}
                  onClick={() => { dispatch(setTypeModalVisibilty(true)); }}
                  tooltip={<Langs str="ADD" />}
                  active={true}
                  width={32}
                />
              </Col>
              <Col lg={12}>
                {renderEvaluationTypes()}
              </Col>
            </Row>
          </div>
        </Col>
        <Col xl={5} className="d-flex align-content-center ms-0 ps-0">
          <div className={styles.menuContentV}>
            <Row className="p-2">
              <Row>
                <Col
                  xl={6}
                  className="d-flex justify-content-start align-items-center p-2 ps-4 mb-4 fw-bold">
                  <label className={styles.menuTitle}>
                    <Langs str="QUESTIONS" />
                  </label>
                </Col>
                <Col
                  xl={6}
                  className="d-flex justify-content-end align-items-center p-2 pe-4 mb-4">
                  {!onAddEvaluationRowVisibility && selectedEvaluationTypeLocal &&
                    <ImageButton
                      image={addIcon}
                      imageFocus={addIconFocus}
                      onClick={handleAddEvaluationFieldVisibility}
                      tooltip={<Langs str="ADD" />}
                      active={true}
                      width={32}
                    />}
                </Col>
                <Dropdown
                  value={selectedEvaluationTypeLocal}
                  onChange={handleEvaluationTypeChange}
                  options={evaluationsTypes}
                  optionLabel="title"
                  placeholder="Type"
                  className="w-50 fw-semibold text-black ms-3 mb-3"
                  style={{ maxWidth: "100%" }}
                />
                {renderEvaluationFields()}
                {renderAddEvaluationRow()}
                {renderConfirmDeleteDialog()}
              </Row>
            </Row>
          </div>
        </Col>
        <EvaluationTypeModal />
        <EvaluationFieldModal />
      </Row>
  }

  return (
    <div className={styles.settingsView}>
      {isMobile == 2 ? (
        <div className="topMenu">
          <Row>
            <Col md={5}>
              <span className="headerTitle">
                <Langs str="Réglages" />
              </span>
              <Dropdown value={selectedMenu} 
                        onChange={(e) => handleMenuChangeV2(e)} 
                        options={menuList.map((item, index) => ({
                          value: index,
                          name: item
                        }))}
                        optionLabel="name" 
                        placeholder="Select a City" 
                        className="w-full md:w-14rem "
                        style={{height: '3rem'}} />
            </Col>
          </Row>
        </div>
      ) : (
        <div>
          <div className={styles.leftMenu}>
            <label className={styles.leftMenuTitle}>
              <Langs str="Settings" />
            </label>
            {menuList.map((item, index) => (
              <button
                key={`MENU_${index}`}
                value={index}
                className={styles.settingsButton}
                onClick={() => setSelectedMenu(index)}
              >
                <div
                  className={
                    item === menuList[selectedMenu]
                      ? styles.selectedLabel
                      : styles.menuLabel
                  }
                >
                  <Langs str={item} />
                </div>
              </button>
            ))}
            <label className={styles.versionLabel}>Version {pjson.version}</label>
          </div>
        </div>
      )}
      {renderMenuContent()}
      <ShiftFormModal
        show={showFormPopup}
        onHide={() => dispatch(hideFormPopup())}
      />
    </div>
  );
}