import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { showCollabModal, setPersmissionsModal, setPermissions, setTeamPermissions } from "../../components/collabs/collabsSlice";
import { useDispatch, useSelector } from "react-redux";
import styled, { keyframes } from 'styled-components';
import styles from './CollabsView.module.css';
import ImageButton from '../../components/buttons/ImageButton';
import Langs from '../../app/lang/langs';
import successIcon from "../../assets/images/successResponse.png";
import failedIcon from "../../assets/images/failedResponse.png";
import loadingIcon from "../../assets/images/loadingResponse.png";
import { isAdmin } from '../../app/utils/helpers';
import { Dialog } from 'primereact/dialog';
import { SelectButton } from 'primereact/selectbutton'
import { Checkbox } from "primereact/checkbox";
import { Button as ButtonPR } from 'primereact/button';
import { DynamicButton } from '../../components/buttons/DynamicButton';
import { showSuccessToast, showErrorToast } from '../../app/utils/helpers';
import { RadioButton } from '../../components/buttons/RadioButton';
import { config } from '../../app/utils/config';
import { Col } from 'react-bootstrap';


export default function PermissionsModal(props) {

    const dispatch = useDispatch();
    const mounted = useRef(null);
    const userRoleId = useSelector((state) => state.login.userRoleId);
    const visible = useSelector((state) => state.collabs.permissionsModal);
    const collabModalData = useSelector(state => state.collabs.collabModal);
    const permissions = useSelector((state) => state.collabs.permissions);
    const teamPermissions = useSelector((state) => state.collabs.teamPermissions);

    function handlePermissionsClose() {
        dispatch(showCollabModal(false))
        dispatch(setPersmissionsModal(false))
    }
    
    const findPermissionType = (scopeName) => {
        const permission = permissions.find(perm => perm.scope.name === scopeName);
        return (permission && permission.scope.type != null) ? permission.scope.type : "remove";
    };
    const findPermissionTypeGroups = (scopeName) => {
        const permission = teamPermissions.find(perm => perm.scope.name === scopeName);
        return (permission && permission.scope.type != null) ? permission.scope.type : "remove";
    };

    const [activeButtonPlanning, setActiveButtonPlanning] = useState(findPermissionType(config.scopes.shifts));
    const [activeButtonLeaves, setActiveButtonLeaves] = useState(findPermissionType(config.scopes.leaves));
    const [activeButtonGroups, setActiveButtonGroups] = useState(findPermissionType(config.scopes.groups));
    const [activeButtonCollabs, setActiveButtonCollabs] = useState(findPermissionType(config.scopes.collabs));
    const [activeButtonOwnLeaves, setActiveButtonOwnLeaves] = useState(findPermissionType(config.scopes.ownLeaves));
    const [activeButtonWarnings, setActiveButtonWarnings] = useState(findPermissionType(config.scopes.warnings));
    const [activeButtonEvaluations, setActiveButtonEvaluations] = useState(findPermissionType(config.scopes.evaluations))

    useEffect(() => {
        setActiveButtonPlanning(findPermissionTypeGroups(config.scopes.shifts));
        setActiveButtonLeaves(findPermissionTypeGroups(config.scopes.leaves));
        setActiveButtonGroups(findPermissionTypeGroups(config.scopes.groups));
        setActiveButtonEvaluations(findPermissionTypeGroups(config.scopes.evaluations));
        if (collabModalData && collabModalData.isTeamCollab)
            setActiveButtonOwnLeaves(findPermissionTypeGroups(config.scopes.ownLeaves));
    }, [teamPermissions])

    useEffect(() => {
        setActiveButtonCollabs(findPermissionType(config.scopes.collabs));
        setActiveButtonWarnings(findPermissionType(config.scopes.warnings));
    }, [permissions])

    const handleButtonClickPlanning = (buttonId) => {
        setActiveButtonPlanning(buttonId);
    };

    const handleButtonClickLeaves = (buttonId) => {
        setActiveButtonLeaves(buttonId);
    };

    const handleButtonClickGroups = (buttonId) => {
        setActiveButtonGroups(buttonId);
    };
    const handleButtonClickCollabs = (buttonId) => {
        setActiveButtonCollabs(buttonId);
    }
    const handleButtonClickWarnings = (buttonId) => {
        setActiveButtonWarnings(buttonId);
    }
    const handleButtonClickEvaluations = (buttonId) => {
        setActiveButtonEvaluations(buttonId)
    }

    const handleCanValidateHisLeaves = () => {
        let permissionType = config.permissionTypes.edit
        if (activeButtonOwnLeaves == config.permissionTypes.edit) {
            permissionType = config.permissionTypes.view
        }
        setActiveButtonOwnLeaves(permissionType)
    }
    // useEffect(()=>{
    //     console.log("active state: ", activeButtonEvaluations)
    // },[activeButtonEvaluations])
    
    function updateCollabPermission() {
        const categorizeScope = (scopeName, scopeState) => {
            if (scopeState === config.permissionTypes.view ) {
                updateBody.view_scopes.push(scopeName);
            } else if (scopeState === config.permissionTypes.edit ) {
                updateBody.edit_scopes.push(scopeName);
            } else if (scopeState === config.permissionTypes.remove) {
                updateBody.remove_scopes.push(scopeName);
            } 
        };
        let updateBody = {};
        if (props.page === "groups") {
            updateBody = {
                "id_collab": collabModalData.id,
                "view_scopes": [],
                "edit_scopes": [],
                "remove_scopes": [],
                "id_team": collabModalData.id_team
            };
            categorizeScope( config.scopes.shifts , activeButtonPlanning);
            categorizeScope( config.scopes.leaves , activeButtonLeaves);
            categorizeScope( config.scopes.groups , activeButtonGroups);
            categorizeScope( config.scopes.evaluations, activeButtonEvaluations)
            if (collabModalData && collabModalData.isTeamCollab)
                categorizeScope(config.scopes.ownLeaves, activeButtonOwnLeaves);
            handlePermissionsClose();
            dispatch(setTeamPermissions(updateBody));
        }
        if (props.page === "collabs") {
            updateBody = {
                "id_collab": collabModalData.id,
                "view_scopes": [],
                "edit_scopes": [],
                "remove_scopes": []
            };
            categorizeScope(config.scopes.collabs, activeButtonCollabs);
            categorizeScope(config.scopes.warnings, activeButtonWarnings);
            handlePermissionsClose();
            dispatch(setPermissions(updateBody));
        }
    }


    function renderPermissions() {

        if (props.page === "groups") {
            const localPermissions = [
                { scope: config.scopes.shifts , activeState: "activeButtonPlanning", handleButtonClick: handleButtonClickPlanning },
                { scope: config.scopes.leaves , activeState: "activeButtonLeaves", handleButtonClick: handleButtonClickLeaves },
                { scope: config.scopes.groups , activeState: "activeButtonGroups", handleButtonClick: handleButtonClickGroups },
                { scope: config.scopes.evaluations , activeState: "activeButtonEvaluations", handleButtonClick: handleButtonClickEvaluations }
            ];
            const filteredLocalPermissions = localPermissions.filter(localPerm =>
                teamPermissions.some(perm => perm.scope.name === localPerm.scope)
            );
            return <div>
                {filteredLocalPermissions.map(teamPermissions => (
                    <div className='col-12 row border-top py-2 ' key={teamPermissions.scope}>
                        <Col lg={8} xs={4} className='pt-2'><Langs str={teamPermissions.scope} /></Col>
                        <Col lg={4} xs={8} className=''>
                            <Col className={`${styles.buttonGroupStyle} d-flex justify-content-center p-1 mx-2`}>
                                <ButtonPR
                                    label={<Langs str='visible' />}
                                    icon="pi pi-eye"
                                    className={`${styles.buttonShadow} m-1 ${eval(teamPermissions.activeState) === 'view' ? 'p-button-success' : 'p-button-secondary'}`}
                                    onClick={() => teamPermissions.handleButtonClick('view')}
                                />
                                <ButtonPR
                                    label={<Langs str='edit' />}
                                    icon="pi pi-pencil"
                                    className={`${styles.buttonShadow} m-1 ${eval(teamPermissions.activeState) === 'edit' ? 'p-button-warning' : 'p-button-secondary'}`}
                                    onClick={() => teamPermissions.handleButtonClick('edit')}
                                />
                            </Col>
                        </Col>
                    </div>
                ))}
                {collabModalData && collabModalData.isTeamCollab &&
                    <RadioButton
                        title={"Can validate his own leaves"}
                        selected={activeButtonOwnLeaves == config.permissionTypes.edit}
                        setSelected={handleCanValidateHisLeaves} />}
            </div>
        }
        if (props.page === "collabs") {
            const localPermissions = [
                { scope: config.scopes.collabs, activeState: "activeButtonCollabs", handleButtonClick: handleButtonClickCollabs }
            ];
            const filteredLocalPermissions = localPermissions.filter(localPerm =>
                permissions.some(perm => perm.scope.name === localPerm.scope)
            );
            return filteredLocalPermissions.map(permission => (
                <div className='col-12 row border-top py-2 ' key={permission.scope}>
                    <Col md={2} xs={2} lg={6} className='pt-2'><Langs str={permission.scope} /></Col>
                    <Col md={10} xs={10} lg={6} className='mx-0 px-0'>
                        <div className={`${styles.buttonGroupStyle} d-flex justify-content-center p-1 mx-2`}>
                            <ButtonPR
                                label={<Langs str='invisible' />}
                                icon="pi pi-eye-slash"
                                className={`${styles.buttonShadow} m-1 ${eval(permission.activeState) === 'remove' ? 'p-button-danger' : 'p-button-secondary'}`}
                                onClick={() => permission.handleButtonClick('remove')}
                            />
                            <ButtonPR
                                label={<Langs str='visible' />}
                                icon="pi pi-eye"
                                className={`${styles.buttonShadow} m-1 ${eval(permission.activeState) === 'view' ? 'p-button-success' : 'p-button-secondary'}`}
                                onClick={() => permission.handleButtonClick('view')}
                            />
                            <ButtonPR
                                label={<Langs str='edit' />}
                                icon="pi pi-pencil"
                                className={`${styles.buttonShadow} m-1 ${eval(permission.activeState) === 'edit' ? 'p-button-warning' : 'p-button-secondary'}`}
                                onClick={() => permission.handleButtonClick('edit')}
                            />
                        </div>
                    </Col>
                </div>
            ));
        }
    }


    const headerElement = (
        <div className='row'>
            <div className='col-12 row'>
                <div className='col-12 d-inline-flex'>
                    <span>{collabModalData.prenom} {collabModalData.nom}</span>
                    <div className={`${styles.collabPoste} mt-2 ms-1`}>
                        {collabModalData.poste || collabModalData.type || ""}
                    </div>
                </div>

            </div>
            <div className='col-12'>
                <span className={styles.collabEmail}>{collabModalData.email || ""}</span>
            </div>
        </div>
    );

    const footerContent = (
        <div className='d-flex justify-content-around'>
            <div>
                <DynamicButton
                    onClick={() => { updateCollabPermission() }}
                    className='me-auto ms-4 p-button-success'
                    title={<Langs str="update" />}
                    active={true}
                    color="green"
                />
            </div>
        </div>
    );
    return (
        <div className="card flex justify-content-center">
            <Dialog
                visible={visible}
                draggable={false}
                position='center'
                modal
                resizable={false}
                header={headerElement}
                footer={footerContent}
                style={{ width: '50rem' }}
                onHide={() => { handlePermissionsClose() }}
            >
                {renderPermissions()}
            </Dialog>
        </div>
    )
}