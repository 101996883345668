import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import {
  showCollabModal,
  setResetPasswordModal,
  setPersmissionsModal,
  setCollabModalData,
  updateCollabs,
  getCollabs,
  getPermissions,
  getTeamPermissions,
  getAllCollabs
} from "../../components/collabs/collabsSlice"
import { useDispatch, useSelector } from "react-redux"
import styled, { keyframes } from 'styled-components'
import styles from './CollabsView.module.css'
import Langs from '../../app/lang/langs'
import moment from 'moment';
import { DynamicButton } from '../../components/buttons/DynamicButton'
import { CustomColorPicker } from '../../components/inputs/CustomColorPicker'
import { showErrorToast } from "../../app/utils/helpers"
import { setIsLoading } from "../common/commonSlice"
import { checkPermission } from '../../app/utils/helpers'
import { config } from '../../app/utils/config'
import {
  setEvaluationAddModal,
  setOnEvaluateTeamId,
  setOnEvaluateCollab,
  setOnEvaluateDate,
  setAllowedToEdit
} from '../../components/evaluations/evaluationsSlice'
import { NumberInput } from '../../components/inputs/NumberInput'

function renderStyledModalStyle(fullModal, minimize, isMobile) {

  let animation = keyframes`
    from { transform: translateX(100%); }
    to { transform: translateX(0); }`

  if (!fullModal && minimize) {
    animation = keyframes`
      from { transform: translateX(0); }
      to { transform: translateX(0); }`
  } else if (fullModal) {
    animation = keyframes`
      from { transform: translateX(60%); }
      to { transform: translateX(0); }`
  }

  let modalWidth = '40%';
  if (isMobile === 2) {
    modalWidth = '100%';
  } else if (isMobile === 3) {
    modalWidth = '40%';
  } else if (fullModal) {
    modalWidth = '100%';
  }

  const StyledModal = styled(Modal)`
    margin-top: 0;
    margin-bottom: 0;
    position: fixed;
    top: 0;
    right: 0;
    width: ${modalWidth};
    height: 100vh;
    background-color: white;
    padding: 0 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-left: 1px solid #ccc;
    margin-inline-start: auto;
    animation: ${animation} 0.5s ease-out forwards;`
  return StyledModal
}

export default function CollabModalDetails(props) {

  const dispatch = useDispatch()

  const userRoleId = useSelector((state) => state.login.userRoleId)
  const [fullModalTrigger, setFullModalTrigger] = useState(false)
  const [minimize, setMinimize] = useState(false)
  const [StyledModal, setStyledModal] = useState(Modal)
  const collabData = useSelector(state => state.collabs.collabModal)
  const [collabUpdate, setCollabUpdate] = useState(null)
  const selectedTeamsIds = useSelector((state) => state.teams.selectedTeamsIds)
  const permissions = useSelector((state) => state.collabs.permissions);
  const teamPermissions = useSelector((state) => state.collabs.teamPermissions);
  const isMobile = useSelector((state) => state.settings.isMobile)

  const [fields, setFields] = useState({
    id: {},
    matricule: {},
    nom: {},
    prenom: {},
    mobileNumber: {},
    email: {},
    company: {},
    localization: {},
    team: {},
    poste: {},
    statut: {},
    entryDate: { type: "DATE" },
    ancienneteeDate: { type: "DATE" },
    exitDate: { type: "DATE" },
    solde_cp_old_remaining: { label: "CPN-1 SILAE" },
    solde_cp_current_remaining: { label: "CPN SILAE" },
    solde_cp_old: { label: "CPN-1" },
    solde_cp_old_expiry: { label: "Expiration CPN-1", type: "DATE" },
    solde_cp_current: { label: "CPN-1" }
  })


  useEffect(() => {
    if (collabData && collabData.id) {

      if (props.page == "groups") {
        dispatch(getTeamPermissions({ id_collab: collabData.id, id_team: collabData.id_team }))
      }
      if (props.page == "collabs") {
        dispatch(getPermissions({ id_collab: collabData.id }))
      }
    }
  }, [collabData])

  useEffect(() => {
    if (props.page === "groups" && props.type == "Collabs") {
      setFields(currentFields => ({
        ...currentFields,
        auto: { type: "BOOL" },
        color: { type: "COLOR" }
      }))
    } else if (props.page === "collabs") {
      setFields(currentFields => ({
        ...currentFields,
        frais_tel: { label: "Frais téléphonique", type: "NUMBER_INPUT" },
        forfait_deplacement: { label: "Forfait déplacement", type: "NUMBER_INPUT" }
      }))
    }
  }, [props.page, props.type])


  useEffect(() => {
    setStyledModal(renderStyledModalStyle(fullModalTrigger, minimize, isMobile))
  }, [collabData, fullModalTrigger])

  useEffect(() => {
    renderPermissionsBtn()
  }, [props.page])

  useEffect(() => {
    if (isMobile == 2) {
      setFullModalTrigger(true)
    } else {
      setFullModalTrigger(false)
    }

  }, [isMobile])

  const handleClose = (wipe) => {
    dispatch(showCollabModal(false))
    if (wipe) {
      setCollabUpdate(null)
      dispatch(setCollabModalData({ show: false }))
    }
  }

  const colorBodyTemplate = (data) => {
    const color = collabUpdate && collabUpdate.color ?
      (collabUpdate.color || "#000000") :
      (data.color || "#000000")
    return (
      <CustomColorPicker
        color={color}
        onChange={handleColorChange}
        disabled={!checkPermission(config.scopes.groups, config.permissionTypes.edit, collabData.id_team)}
      />
    )
  }

  function handleColorChange(color) {
    let params = { id: collabData.id, color: color }
    setCollabUpdate(current => ({
      ...current,
      ...params
    }))
  }

  const autoCollabBodyTemplate = (data) => {
    return <input type="checkbox"
      id={data.id}
      checked={collabUpdate && typeof collabUpdate.auto != 'undefined' ? collabUpdate.auto : data.auto}
      disabled={!checkPermission(config.scopes.groups, config.permissionTypes.edit, collabData.id_team)}
      onChange={(e) => handleAutoChange(e.target.checked)} />
  }

  function handleAutoChange(checked) {
    let params = { id: collabData.id, auto: checked }
    setCollabUpdate(current => ({
      ...current,
      ...params
    }))
  }

  const numberInputBodyTemplate = (data, key) => {
    return <NumberInput
      min={0}
      max={999}
      id={data.id}
      data-key={data[key]}
      // disableKeyboard = {true}
      disabled={!checkPermission(config.scopes.collabs, config.permissionTypes.edit)}
      onChange={(e) => handleFieldChange(key, e.target.value)}
      value={collabUpdate && collabUpdate[key] ? collabUpdate[key] : data[key]} />
  }

  function handleFieldChange(key, value) {
    let params = { id: collabData.id }
    params[key] = value || null
    setCollabUpdate(current => ({
      ...current,
      ...params
    }))
  }

  function renderCollabData() {
    return Object.keys(fields).map((key, details) => {
      let field = fields[key]
      let value = collabData[key]
      if (typeof value == 'undefined')
        return
      if (field.type == "NUMBER_INPUT") {
        value = numberInputBodyTemplate(collabData, key)
      }
      else if (field.type == "DATE" && value !== null) {
        value = moment(value).format("DD-MM-YYYY")
      }
      else if (field.type === "BOOL") {
        value = autoCollabBodyTemplate(collabData)
      }
      else if (field.type === "COLOR") {
        value = colorBodyTemplate(collabData)
      }
      let textColor = 'inherit'
      if (key === 'statut') {
        if (["Présent", "Actif"].includes(value))
          textColor = "green"
        else textColor = "red"
      } else if (key === 'exitDate') {
        textColor = "red"
      }
      if (value)
        return <div key={key} className={`row ${styles.collabCell}`}>
          <p className='col-6 fw-bold my-3 '>{<Langs str={field.label ? field.label : key} />}: </p>
          <p className='col-6 ms-auto my-3'
            style={{
              color: textColor
            }}>
            {value}
          </p>
        </div>
    })
  }

  function renderModalheader() {
    return (
      <Modal.Header closeButton>
        <Modal.Title>
          {collabData.nom} {collabData.prenom}</Modal.Title>
      </Modal.Header>
    )
  }

  function renderModalBody() {
    return (
      <Modal.Body className={styles.modalBody}>
        {renderCollabData()}
      </Modal.Body>
    )
  }

  function renderResetPasswordBtn() {
    if (checkPermission(config.scopes.collabs, config.permissionTypes.edit)) {
      if (props.page == "collabs") {
        return (
          <DynamicButton
            onClick={() => resetDialogVisible()}
            className='me-auto'
            title={<Langs str="reset_password" />}
            active={true}
            color="red"
          />
        )
      }
    }
  }
  function renderEvaluationBtn() {
    if (checkPermission(config.scopes.evaluations, config.permissionTypes.edit, collabData.id_team)) {
      if (props.page == "groups" && props.type == "Collabs") {
        return (
          <DynamicButton
            onClick={() => addEvaluationDialog()}
            className='me-auto'
            title={<Langs str="EVALUATE" />}
            active={true}
            color="blue"
          />
        )
      }
    }
  }

  function renderPermissionsBtn() {
    if (props.page === "groups" && props.type == "Managers") {
      if (checkPermission(config.scopes.groups, config.permissionTypes.edit, collabData.id_team)) {
        if (teamPermissions.length > 0) {
          return (
            <DynamicButton
              onClick={() => permissionsDialogVisible()}
              className='me-auto'
              title={<Langs str="set_permissions" />}
              active={true}
              color="blue"
            />
          )
        }
      }
    }
    if (props.page == "collabs") {
      if (checkPermission(config.scopes.collabs, config.permissionTypes.edit, null)) {
        if (permissions.length > 0) {
          return (
            <DynamicButton
              onClick={() => permissionsDialogVisible()}
              className='me-auto'
              title={<Langs str="set_permissions" />}
              active={true}
              color="blue"
            />
          )
        }
      }
    }
  }

  function renderSaveBtn() {

    let modifiedFields = []
    if (collabUpdate) {
      modifiedFields = Object.keys(collabUpdate).filter(key => collabUpdate[key] != collabData[key])
    }
    if (modifiedFields.length) {
      return (
        <DynamicButton
          onClick={() => handleSaveBtn()}
          className='me-auto'
          title={<Langs str="Save" />}
          active={true}
          color="green"
        />
      )
    }
  }

  const handleSaveBtn = async () => {
    if (!collabData) return
    dispatch(setIsLoading(true))
    let response = await dispatch(updateCollabs({
      items: [collabUpdate]
    }))
    let error = response["payload"]["responseError"]
    if (error) {
      showErrorToast()
    }
    if (props.page == "groups") {
      dispatch(getCollabs({ selectedTeamsIds, isCurrent: true }))
    } else {
      dispatch(getAllCollabs())
    }
    dispatch(setIsLoading(false))
    handleClose({ wipe: true })
  }

  function resetDialogVisible() {
    handleClose()
    dispatch(setResetPasswordModal(true))
  }

  function addEvaluationDialog() {
    handleClose()
    if(collabData){
      dispatch(setOnEvaluateTeamId(collabData.id_team))
      dispatch(setOnEvaluateCollab(collabData.id))
    }
    dispatch(setAllowedToEdit(true))
    dispatch(setOnEvaluateDate(Date.now()))
    dispatch(setEvaluationAddModal(true))
  }

  function permissionsDialogVisible() {
    handleClose()
    dispatch(setPersmissionsModal(true))
  }

  function renderModalFooter() {
    return (
      <Modal.Footer className={`${styles.modalFooter} d-flex m-auto`}>
        {props.page === "collabs" && (
          <div>
            {collabData.typeLogin === "EMAIL" && renderResetPasswordBtn()}
          </div>
        )}
        <div>
          {renderSaveBtn()}
        </div>
        <div>
          {renderPermissionsBtn(userRoleId)}
        </div>
        <div>
          {renderEvaluationBtn()}
        </div>
      </Modal.Footer>
    )
  }

  return (
    <StyledModal
      show={collabData.show}
      onHide={() => { handleClose({ wipe: true }) }}
      backdrop="static"
      keyboard={false}
      animation={false}
      dialogClassName={styles.modalDialog}
      scrollable={true}
    >
      {renderModalheader()}
      {renderModalBody()}
      {renderModalFooter()}
    </StyledModal>
  )
}
