import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import NetworkManager from "../../app/managers/networkManager";
import { config } from "../../app/utils/config";
import { checkPermission, generateUniqueId } from "../../app/utils/helpers";
import { store } from "../../app/store/store";
import { showPopup } from "../../../src/containers/common/commonSlice";
import { setIsLoading } from "../../../src/containers/common/commonSlice";
import moment from "moment";

const initialState = {
  list: [],
  currentList: [],
  updateList: [],
  id_groupe: null,
  collaboratorsList: [],
  allCollabsList: [],
  allCollabsLoader: 'loading',
  isLoadingCra: false,
  zipGenerationProgress: null,
  collabModal: {
    show: false
  },
  resetPasswordModal: false,
  resetPasswordResponse: null,
  permissionsModal: false,
  permissions: [],
  teamPermissions: [],
  updateTeamPermissionsRes: null,
  updatePermissionsRes: null,
  managers: [],
  warningsList: [],
  selectedView: 0,
  warningDialog: false,
  warningData: null,
  warningDeleteRes: null,
  warningEditRes: null,
  warningUpdateBody: null,
  addWarningDialog: false,
  addWarningRes: null,
  addedWarningBody: null,
  warningCatgories: [
    { value: "warning", color: "#f59e0b" },
    { value: "recall the order", color: "#e74c3c" },
    { value: "formal notice", color: "#0ea5e9" },
    { value: "Demande rupture conventionnelle", color: "#1e8449" },
    { value: "Demande prime", color: "#6c3483" },
    { value: "Demande augmentation salaire", color: "#e67e22" }
  ]
}

export const collabsSlice = createSlice({
  name: "collabs",
  initialState,
  reducers: {
    setCollabs: (state, action) => {
      state.list = action.payload
    },
    setCurrentCollabs: (state, action) => {
      state.currentList = action.payload
    },
    setWarningDeleteRes: (state, action) => {
      state.warningDeleteRes = action.payload
    },
    setWarningEditRes: (state, action) => {
      state.warningEditRes = action.payload
    },
    setAddWarningRes: (state, action) => {
      state.addWarningRes = action.payload
    },
    setAddedWarningBody: (state, action) => {
      state.addedWarningBody = action.payload
    },
    setWarningUpdateBody: (state, action) => {
      state.warningUpdateBody = action.payload
    },
    setSelectedView: (state, action) => {
      state.selectedView = action.payload
    },
    setAddWarningDialog: (state, action) => {
      state.addWarningDialog = action.payload
    },
    setCollabModalData: (state, action) => {
      state.collabModal = action.payload
    },
    setUpdateTeamPermissionsRes: (state) => {
      state.updateTeamPermissionsRes = null
    },
    setUpdatePermissionsRes: (state) => {
      state.updatePermissionsRes = null
    },
    showCollabModal: (state, action) => {
      state.collabModal.show = action.payload
    },
    setResetPasswordModal: (state, action) => {
      state.resetPasswordModal = action.payload
    },
    setPersmissionsModal: (state, action) => {
      state.permissionsModal = action.payload
    },
    setWarningDetailsDialog: (state, action) => {
      state.warningDialog = action.payload
    },
    setWarningData: (state, action) => {
      state.warningData = action.payload
    },
    setResetPasswordResponse: (state) => {
      state.resetPasswordResponse = null;
    },
    setUpdatedCurrentCollabs: (state, action) => {
      state.updateList = action.payload
      let updatedList = state.currentList.reduce((array, current) => {
        let collab = Object.assign({}, current)
        let newCollabs = state.updateList.filter(c => c.id == current.id)
        if (newCollabs.length) {
          Object.keys(newCollabs[0]).map(key => {
            collab[key] = newCollabs[0][key]
          })
        }
        collab["solde"] = collab["solde_cp_current"] + collab["solde_cp_old"]
        array.push(collab)
        return array
      }, [])
      state.currentList = updatedList
    },
    setCollabsGroupeId: (state, action) => {
      state.id_groupe = action.payload;
    },
    addCollab: (state, action) => {
      state.push({
        value: generateUniqueId(),
        label: action.payload.name,
        email: action.payload.email,
        groupeIds: [],
      });
    },
    addCollabToGroupe: (state, action) => {
      if (action.payload.id && action.payload.id_groupe) {
        let collabs = state.list.filter(
          (collab) => collab.value === action.payload.id
        )
        if (collabs.length) {
          let collab = collabs[0]
          collab.groupeIds = [action.payload.id_groupe]
        }
      }
    },
    removeCollabFromGroupe: (state, action) => {
      if (action.payload.id && action.payload.id_groupe) {
        let collabs = state.filter((collab) => collab.id === action.payload.id);
        if (collabs.length) {
          let collab = collabs[0];
          const index = collab.groupeIds.indexOf(action.payload.id_groupe);
          if (index > -1) {
            collab.groupeIds.splice(index, 1);
          }
        }
      }
    }, setIsLoadingCra: (state, action) => {
      state.isLoadingCra = action.payload
    },
    addNewlyEvaluated: (state, action) => {
      const [id, numberOfEvaluations] = action.payload
      if (numberOfEvaluations) {
        state.list = state.list.map(item => {
          if (item.id === id) {
            return {
              ...item,
              evaluated: item.evaluated + numberOfEvaluations
            };
          }
          return item
        })
      }
    },
    setZipGenerationProgress(state, action) {
      state.zipGenerationProgress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCollabs.fulfilled, (state, action) => {

      let teamCollabsIds = {}
      if (
        action.payload.responseData &&
        action.payload.responseData["collabs"]
      ) {
        let collabsList = []

        for (const key in action.payload.responseData["collabs"]) {
          let collab = action.payload.responseData["collabs"][key]
          let data = {
            value: collab["id"],
            label: `${collab["prenom"]} ${collab["nom"]}`,
            nom: collab["nom"],
            prenom: collab["prenom"],
            mobileNumber: collab["mobile_number"],
            email: collab["email"],
            matricule: collab["matricule"],
            company: collab["company"],
            id_company: collab["id_company"],
            poste: collab["poste"],
            order: collab["order"],
            id: collab["id"],
            groupeIds: [state.id_groupe],
            id_team: collab["id_team"],
            statut: collab["statut"],
            entryDate: collab["entree_date"],
            ancienneteeDate: collab["anciennetee_date"],
            exitDate: collab["sortie_date"],
            color: collab["color"],
            auto: collab["auto"],
            localization: collab["localization"],
            evaluated: collab["evaluated"],
            totalEvaluationFields: collab["totalEvaluationFields"],
            entree_date_team: collab["start_date"],
            sortie_date_team: collab["end_date"]
          }
          if (collab["type_collab"] == 'interne' || collab["localization"] == 'BEYROUTH') {
            data["solde_cp_current"] = Number(collab["solde_cp_current"])
            data["solde_cp_old"] = Number(collab["solde_cp_old"])
            data["solde_cp_old_expiry"] = collab["solde_cp_old_expiry"]
            data["solde"] = Number((Number(collab["solde_cp_current"]) + Number(collab["solde_cp_old"])).toFixed(2))
          }
          if (collab["type_collab"] == 'interne' && collab["localization"] != 'BEYROUTH') {
            data["solde_cp_current_remaining"] = Number(collab["solde_cp_current_remaining"])
            data["solde_cp_old_remaining"] = Number(collab["solde_cp_old_remaining"])
          }
          if (collab["id_team_delegate"])
            data.id_team_delegate = collab["id_team_delegate"]

          collabsList.push(data)

          if (teamCollabsIds[collab.id_team]) {
            teamCollabsIds[collab.id_team].push(collab.id)
          } else {
            teamCollabsIds[collab.id_team] = [collab.id]
          }

        }
        let sortedList = collabsList.sort((a, b) => a.order - b.order)
        // check if it's this month's data
        if (action.payload.body.isCurrent) {
          state.currentList = sortedList
        } else {
          state.list = sortedList
        }
      }
      if (
        action.payload.responseData &&
        action.payload.responseData["managers"]
      ) {
        state.managers = []
        action.payload.responseData["managers"].map(manager => {
          if (teamCollabsIds[manager.id_team] && teamCollabsIds[manager.id_team].includes(manager.id)) {
            manager.isTeamCollab = true
          }
          state.managers.push(manager)
        })
      }
    });
    builder.addCase(getMembers.fulfilled, (state, action) => {
      if (
        action.payload.responseData &&
        action.payload.responseData["groupes"]
      ) {
        let collaboratorsMap = {};
        for (const key in action.payload.responseData["groupes"]) {
          let groupe = action.payload.responseData["groupes"][key];
          for (const collabKey in groupe["collabs"]) {
            let collab = groupe["collabs"][collabKey]
            if (!collaboratorsMap[collab["id"]]) {
              collaboratorsMap[collab["id"]] = {
                value: collab["id"],
                label: `${collab["prenom"]} ${collab["nom"]}`,
                nom: collab["nom"],
                id: collab["id"],
                email: collab["email"],
                prenom: collab["prenom"],
                matricule: collab["matricule"],
                id_silae: collab["id_silae"],
                company: collab["company"],
                mobileNumber: collab["mobile_number"],
                poste: collab["poste"],
                order: collab["order"],
                groupeIds: [],
                statut: collab["statut"],
                id_team: groupe["id_team"],
                entryDate: collab["entree_date"],
                ancienneteeDate: collab["anciennetee_date"],
                exitDate: collab["sortie_date"],
                color: collab["color"],
                auto: collab["auto"]
              }
            }
            collaboratorsMap[collab["id"]].groupeIds.push(groupe["id"])
          }
        }
        state.collaboratorsList = Object.values(collaboratorsMap).sort((a, b) => a.order - b.order)
      }
    })
    //Try to add it locally
    builder.addCase(addGroupeMembers.fulfilled, (state, action) => {
      if (action.payload.responseData) {
      }
    })
    //Try to remove it locally
    builder.addCase(removeGroupeMembers.fulfilled, (state, action) => {
      if (action.payload.responseData) {
      }
    })
    builder.addCase(updateCollabs.fulfilled, (state, action) => {
      if (action.payload.responseData) {
        state.updateList = []
      }
    })
    builder.addCase(getAllCollabs.fulfilled, (state, action) => {
      if (
        action.payload.responseData &&
        action.payload.responseData["allCollabs"]
      ) {
        let collabsList = [];
        for (const key in action.payload.responseData["allCollabs"]) {
          let collab = action.payload.responseData["allCollabs"][key];

          let entryDateTimestamp = collab["entree_date"] ? new Date(collab["entree_date"]).getTime() : null;
          let exitDateTimestamp = collab["sortie_date"] ? new Date(collab["sortie_date"]).getTime() : null;
          let ancienneteeDateTimestamp = collab["anciennetee_date"] ? new Date(collab["anciennetee_date"]).getTime() : null;
          let soldeCpOldExpiryTimestamp = collab["solde_cp_old_expiry"] ? new Date(collab["solde_cp_old_expiry"]).getTime() : null;

          let data = {
            value: collab["id"],
            label: `${collab["prenom"] ? collab["prenom"].trim() : ''} ${collab["nom"] ? collab["nom"].trim() : ''}`,
            nom: collab["nom"] ? collab["nom"].trim() : '',
            prenom: collab["prenom"] ? collab["prenom"].trim() : '',
            mobileNumber: collab["mobile_number"] ? collab["mobile_number"].trim() : '',
            email: collab["email"] ? collab["email"].trim() : '',
            matricule: collab["matricule"] ? collab["matricule"].trim() : '',
            company: collab["company"] ? collab["company"].trim() : '',
            id_company: collab["id_company"],
            poste: collab["poste"] ? collab["poste"].trim() : '',
            order: collab["order"],
            id: collab["id"],
            groupeIds: [state.id_groupe],
            id_team: collab["id_team"],
            statut: collab["statut"] ? collab["statut"].trim() : '',
            entryDate: entryDateTimestamp,
            ancienneteeDate: ancienneteeDateTimestamp,
            exitDate: exitDateTimestamp,
            color: collab["color"] ? collab["color"].trim() : '',
            auto: collab["auto"],
            localization: collab["localization"] ? collab["localization"].trim() : '',
            team: collab["team_name"] ? collab["team_name"].trim() : '',
            typeLogin: collab["type_login"] ? collab["type_login"].trim() : '',
            solde_cp_current: Number(collab["solde_cp_current"]),
            solde_cp_old: Number(collab["solde_cp_old"]),
            solde_cp_old_expiry: soldeCpOldExpiryTimestamp,
            solde_cp_old_remaining: Number(collab["solde_cp_old_remaining"]),
            solde_cp_current_remaining: Number(collab["solde_cp_current_remaining"]),
            solde: Number((Number(collab["solde_cp_current"]) + Number(collab["solde_cp_old"])).toFixed(2)),
            forfait_deplacement: collab["forfait_deplacement"],
            frais_tel: collab["frais_tel"]
          };

          if (collab["id_team_delegate"])
            data.id_team_delegate = collab["id_team_delegate"];

          collabsList.push(data);
        }
        state.allCollabsList = collabsList.sort((a, b) => a.order - b.order);
        state.allCollabsLoader = 'success';
      }
    });
    builder.addCase(resetPasswordCollab.fulfilled, (state) => {
      state.resetPasswordResponse = "success";
    })
    builder.addCase(resetPasswordCollab.rejected, (state, action) => {
      state.resetPasswordResponse = action.payload || "failed";
    })
    builder.addCase(fetchZipGenerationProgress.fulfilled, (state, action) => {
      if (action.payload && action.payload.progress !== undefined) {
        state.zipGenerationProgress = action.payload.progress;
      }
    });
    builder.addCase(getPermissions.fulfilled, (state, action) => {
      if (action.payload.responseData && Array.isArray(action.payload.responseData.permissions)) {
        const transformedPermissions = action.payload.responseData.permissions.map(permission => ({
          id_team: permission.id_team,
          scope: {
            name: permission.scope,
            type: permission.type
          }
        }));
        state.permissions = transformedPermissions;
      } else {
        state.permissions = [];
      }
    });
    builder.addCase(setTeamPermissions.fulfilled, (state, action) => {
      if (action.payload.responseError) {
        state.updateTeamPermissionsRes = action.payload.responseError.data.error
      } else {
        state.updateTeamPermissionsRes = action.payload.responseData.message;
      }
    });
    builder.addCase(setTeamPermissions.rejected, (state, action) => {
      state.updateTeamPermissionsRes = action.payload || "failed";
    });
    builder.addCase(getTeamPermissions.fulfilled, (state, action) => {
      if (action.payload.responseData && Array.isArray(action.payload.responseData.permissions)) {
        const transformedPermissions = action.payload.responseData.permissions.map(permission => ({
          id_team: permission.id_team,
          scope: {
            name: permission.scope,
            type: permission.type
          }
        }));
        state.teamPermissions = transformedPermissions;
      } else {
        state.teamPermissions = [];
      }
    });
    builder.addCase(setPermissions.fulfilled, (state, action) => {
      if (action.payload.responseError) {
        state.updatePermissionsRes = action.payload.responseError.data.error
      } else {
        state.updatePermissionsRes = action.payload.responseData.message;
      }

    });
    builder.addCase(setPermissions.rejected, (state, action) => {
      state.updatePermissionsRes = action.payload || "failed";
    });
    builder.addCase(getAllWarnings.fulfilled, (state, action) => {
      let warningsList = [];

      if (
        action.payload.responseData &&
        action.payload.responseData["warnings"]
      ) {
        for (const key in action.payload.responseData["warnings"]) {
          let warning = action.payload.responseData["warnings"][key];

          // Convert relevant date strings to timestamps
          let warningDateTimestamp = warning["warning_date"] ? new Date(warning["warning_date"]).getTime() : null;
          let createdAtTimestamp = warning["created_at"] ? new Date(warning["created_at"]).getTime() : null;
          let updatedAtTimestamp = warning["updated_at"] ? new Date(warning["updated_at"]).getTime() : null;

          // Construct the data object for each warning
          let data = {
            id: warning.id,
            id_collab: warning.id_collab,
            category: warning.category ? warning.category.trim() : '',
            status: warning.status ? warning.status.trim() : '',
            warning_date: warningDateTimestamp,
            comments: warning.comments ? warning.comments : '',
            created_at: createdAtTimestamp,
            updated_at: updatedAtTimestamp,
            created_by: {
              id: warning.created_by.id,
              nom: warning.created_by.nom ? warning.created_by.nom.trim() : '',
              prenom: warning.created_by.prenom ? warning.created_by.prenom.trim() : ''
            },
            updated_by: {
              id: warning.updated_by.id,
              nom: warning.updated_by.nom ? warning.updated_by.nom.trim() : '',
              prenom: warning.updated_by.prenom ? warning.updated_by.prenom.trim() : ''
            },
            collab_nom: warning.collab_nom ? warning.collab_nom.trim() : '',
            collab_prenom: warning.collab_prenom ? warning.collab_prenom.trim() : '',
            collab_matricule: warning.collab_matricule ? warning.collab_matricule.trim() : '',
            collab_email: warning.collab_email ? warning.collab_email.trim() : '',
            poste: warning.poste ? warning.poste.trim() : '',
            team_name: warning.team_name ? warning.team_name.trim() : ''
          }

          let category = state.warningCatgories.filter(cat=>cat.value==warning.category)
          if(category.length>0) data.color=category[0].color

          warningsList.push(data)
        }

        state.warningsList = warningsList
      }
    });
    builder.addCase(deleteWarning.fulfilled, (state, action) => {
      if (!action.payload.responseError) {
        if (action.payload.responseData.status) {
          state.warningDeleteRes = action.payload.responseData.message
          state.warningsList = state.warningsList.filter(warning => warning.id !== action.payload.responseData.deleted_warning)
        }
      }
    })
    builder.addCase(editWarnings.fulfilled, (state, action) => {
      if (!action.payload.responseError) {
        if (action.payload.responseData.status) {
          state.warningEditRes = action.payload.responseData.message;

          state.warningsList = state.warningsList.map(warning => {
            if (warning.id === state.warningUpdateBody.warning_id) {
              const updatedWarning = { ...warning };
              if (state.warningUpdateBody.status !== undefined) {
                updatedWarning.status = state.warningUpdateBody.status;
              }
              if (state.warningUpdateBody.warning_date !== undefined) {
                updatedWarning.warning_date = new Date(state.warningUpdateBody.warning_date).getTime();
              }
              if (state.warningUpdateBody.comments !== undefined) {
                updatedWarning.comments = state.warningUpdateBody.comments;
              }

              console.log(updatedWarning)
              return updatedWarning;
            }
            return warning;
          });
          state.warningUpdateBody = null;
        }
      }
    });
    builder.addCase(addWarning.fulfilled, (state, action) => {
      if (!action.payload.responseError) {
        if (action.payload.responseData.status) {
          state.addWarningRes = action.payload.responseData.message
          state.addedWarningBody = {
            ...state.addedWarningBody,
            id: action.payload.responseData.added_row_id
          };
          state.warningsList.push(state.addedWarningBody)
          state.addedWarningBody = null;
        } else if (!action.payload.responseData.status) {
          state.addWarningRes = action.payload.responseData.message
        }
      }
    })
  },
})




export const getCollabs = createAsyncThunk(
  "planning/getCollabs",
  async ({ selectedTeamsIds, isCurrent }, { getState }) => {
    try {
      let firstDayTimeStamp, lastDayTimeStamp
      if (isCurrent) {
        firstDayTimeStamp = moment().startOf("month").valueOf()
        lastDayTimeStamp = moment().endOf("month").valueOf()
      } else {
        const state = getState()
        const firstDay = new Date(state.planning.selectedDate)
        firstDayTimeStamp = firstDay.getTime()
        let lastDay = new Date(firstDayTimeStamp)
        if (state.selectedView === "WEEKLY") {
          lastDay.setDate(firstDay.getDate() + state.WEEKLY_VIEW_DAYS)
        } else {
          lastDay.setMonth(firstDay.getMonth() + 1)
        }
        lastDayTimeStamp = lastDay.getTime()
      }

      let body = {
        teamsIds: selectedTeamsIds,
        from: firstDayTimeStamp,
        to: lastDayTimeStamp
      }

      if (isCurrent) {
        body.isCurrent = isCurrent
      }

      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.getCollabs,
        body
      )
      return response;
    } catch (error) {
      return error
    }
  }
)
export const getAllCollabs = createAsyncThunk(
  "planning/getAllCollabs",
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState()
      const firstDay = new Date(state.planning.selectedDate)
      let firstDayTimeStamp = firstDay.getTime()
      let lastDay = new Date(firstDayTimeStamp)
      if (state.selectedView === "WEEKLY") {
        lastDay.setDate(firstDay.getDate() + state.WEEKLY_VIEW_DAYS)
      } else {
        lastDay.setMonth(firstDay.getMonth() + 1)
      }
      let body = {
        from: firstDayTimeStamp,
        to: lastDay.getTime()
      }
      const response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.getAllCollabs,
        body
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const resetPasswordCollab = createAsyncThunk(
  "planning/resetCollabPass",
  async (collabId) => {
    try {
      let body = {
        collabId: collabId
      }

      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.resetCollabPassword,
        body
      )
      return response;
    } catch (error) {
      return error
    }
  }
)

export const getPermissions = createAsyncThunk(
  "planning/getPermissions",
  async (body, { rejectWithValue }) => {
    if (!body.id_collab) {
      return rejectWithValue('Missing or invalid id_collab.');
    }
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.getPermissions,
        body
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getTeamPermissions = createAsyncThunk(
  "planning/getTeamPermissions",
  async (body, { rejectWithValue }) => {
    if (!body.id_collab && !body.id_team) {
      return rejectWithValue('Missing or invalid id_collab.');
    }
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.getTeamPermissions,
        body
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const setPermissions = createAsyncThunk(
  "planning/setPermissions",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.setPermissions,
        body
      )
      return response;
    } catch (error) {
      return error
    }
  }
)
export const setTeamPermissions = createAsyncThunk(
  "planning/setTeamPermissions",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.setTeamPermissions,
        body
      )
      return response;
    } catch (error) {
      return error
    }
  }
)

export const generateCraZip = createAsyncThunk(
  "planning/generateCraZip",
  async (body, thunkAPI) => {
    try {
      store.dispatch(setIsLoadingCra(true))
      let response = await NetworkManager.getInstance().fetchFile(
        config.apiPaths.planning.generateCraZip,
        body,
        body.fileName
      )
      store.dispatch(setIsLoadingCra(false))
      return response
    } catch (error) {
      store.dispatch(setIsLoadingCra(false))
      if (error.response && error.response.status === 423) {
        const errorMessage = error.response.data.error || "Génération d'un ZIP déjà en cours.";
        store.dispatch(showPopup({ show: true, title: "ERROR", message: errorMessage }))
      } else {
        store.dispatch(showPopup({ show: true, title: "ERROR", message: "Server error" }))
      }
      return thunkAPI.rejectWithValue(error)
    }
  }
);

export const generateCraPdf = createAsyncThunk(
  "planning/generateCraPdf",
  async (body, thunkAPI) => {
    try {
      console.log("generateCraPdf")
      store.dispatch(setIsLoading(true))
      let response = await NetworkManager.getInstance().fetchFile(
        config.apiPaths.planning.generateCraPdf,
        body,
        body.fileName
      )
      store.dispatch(setIsLoading(false))
      return response
    } catch (error) {
      store.dispatch(setIsLoading(false))
      if (error.message) {
        store.dispatch(showPopup({ show: true, title: "ERROR", message: error.message }))
      } else {
        store.dispatch(showPopup({ show: true, title: "ERROR", message: "Server error" }))
      }
      return thunkAPI.rejectWithValue(error)
    }
  }
);

export const fetchZipGenerationProgress = createAsyncThunk(
  "planning/fetchZipGenerationProgress",
  async (_, thunkAPI) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.zipGenerationProgress,
      )
      if (response && response.responseData) {
        return response.responseData;
      }
    } catch (error) {
      console.error("Error retrieving ZIP generation progress:", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// export const stopZipGeneration = createAsyncThunk(
//   "planning/stopZipGeneration",
//   async (_, thunkAPI) => {
//     try {
//       let response = await NetworkManager.getInstance().postRequest(
//         config.apiPaths.planning.stopZipGeneration,
//         { stop: true }
//       )
//       return response;
//     } catch (error) {
//       console.error("Error stopping ZIP generation:", error);
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );


// Fonction pour convertir une base64 en objet Blob
function base64ToBlob(base64) {
  const byteCharacters = atob(base64);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: 'application/zip' });
}




export const updateCollabs = createAsyncThunk(
  "planning/updateCollabs",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.updateCollabs,
        body
      )
      return response;
    } catch (error) {
      return error
    }
  }
)

export const getMembers = createAsyncThunk(
  "planning/getMembers",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.getMembers,
        body
      );
      return response;
    } catch (error) {
      return error
    }
  }
)

export const addGroupeMembers = createAsyncThunk(
  "planning/addGroupeMembers",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.addGroupeMembers,
        body
      )
      return response
    } catch (error) {
      return error
    }
  }
)

export const removeGroupeMembers = createAsyncThunk(
  "planning/removeGroupeMembers",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.removeGroupeMembers,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const getAllWarnings = createAsyncThunk(
  "planning/getWarnings",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.getAllWarnings,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const deleteWarning = createAsyncThunk(
  "planning/deleteWarning",
  async (warningId) => {
    const body = { warning_id: warningId };
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.deleteWarning,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const editWarnings = createAsyncThunk(
  "planning/editWarnings",
  async (warning) => {
    const body = warning;
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.editWarnings,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const addWarning = createAsyncThunk(
  "planning/addWarning",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.addWarning,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
)

export const {
  setCollabs,
  setCurrentCollabs,
  setWarningUpdateBody,
  setCollabModalData,
  setWarningDeleteRes,
  setWarningEditRes,
  setSelectedView,
  setResetPasswordModal,
  setPersmissionsModal,
  showCollabModal,
  setAddWarningDialog,
  setResetPasswordResponse,
  setWarningDetailsDialog,
  setWarningData,
  setUpdatedCurrentCollabs,
  addCollab,
  addCollabToGroupe,
  setUpdateTeamPermissionsRes,
  setUpdatePermissionsRes,
  removeCollabFromGroupe,
  setCollabsGroupeId,
  setIsLoadingCra,
  setZipGenerationProgress,
  setAddWarningRes,
  setAddedWarningBody,
  addNewlyEvaluated
} = collabsSlice.actions;

export default collabsSlice.reducer;
