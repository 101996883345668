import React from 'react';
import styles from './Common.module.css';

import { useDispatch, useSelector } from "react-redux";
import { SettingsView } from "../settings/SettingsView";
import { PlanningView } from '../planning/PlanningView';
import { CollabsView } from '../collabs/CollabsView';
import { GroupesView } from '../groupes/GroupesView';
import { GroupeModal } from '../../components/groupes/modals/GroupeModal';
import { hideGroupeModal } from './commonSlice';
import { LeavesView } from '../leaves/LeavesView';
import { TimesheetView } from '../timesheet/TimesheetView';
import { checkPermission, isAdmin, isOwner } from '../../app/utils/helpers';
import { ReportsView } from '../reports/ReportsView';
import { config } from '../../app/utils/config';
import EvaluationsView from '../evaluations/EvaluationsView';

export function Body(props) {

    const dispatch = useDispatch()

    const groupeModalType = useSelector((state) => state.common.groupeModalType)
    const userRoleId = useSelector((state) => state.login.userRoleId)
    const myTeam = useSelector((state) => state.teams.myTeam);
    const selectedTeamsIds = useSelector((state) => state.teams.selectedTeamsIds)

    let view = <div />
    if (props.view) {
        switch (props.view.toLowerCase()) {
            case "planning":
                view = <PlanningView />
                break
            case "settings":
                view = <SettingsView />
                break
            case "groupes":
                if (checkPermission(config.scopes.groups, config.permissionTypes.view, selectedTeamsIds))
                    view = <GroupesView />
                break
            case "collabs":
                if (checkPermission(config.scopes.collabs, config.permissionTypes.view) || checkPermission(config.scopes.warnings, config.permissionTypes.view))
                    view = <CollabsView />
                break
            case "leaves":
                view = <LeavesView />
                break
            case "timesheet":
                if (myTeam)
                    view = <TimesheetView />
                break
            case "reports":
                if (isOwner(userRoleId))
                    view = <ReportsView />
                break
            case "evaluations":
                if (checkPermission(config.scopes.evaluations, config.permissionTypes.view, selectedTeamsIds))
                    view = <EvaluationsView />
                break
            default:
                break
        }
    }
    return <div className={styles.body}>
        {view}
        <GroupeModal show={groupeModalType} onHide={() => dispatch(hideGroupeModal())} />
    </div>
}