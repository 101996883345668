import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    checkIfEqual,
    getStatusTagClass,
    isAdmin,
    isEmpty,
    setAndReturnTimeForDate,
    showErrorToast,
    generateUniqueId
} from "../../app/utils/helpers";
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from "primereact/checkbox";
import saveIcon from '../../assets/images/save-outline.svg'
import addIcon from "../../assets/images/add.png";
import addIconFocus from "../../assets/images/addFocus.png";
import exportIcon from "../../assets/images/export.png";
import exportIconFocus from "../../assets/images/exportFocus.png";
import { Col, Row, Toast } from "react-bootstrap";
import Langs from "../../app/lang/langs";
import styles from "./Timesheet.module.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import {
    getCollabPlanning,
    getTimesheets,
    setSelectedPlanning,
    setModifiedPlanning,
    setSelectedTimesheet,
    setHasModifications
} from "./timesheetSlice";
import { Tag } from "primereact/tag";
import { TransparentTimePicker } from "../../components/buttons/TranparentTimePicker";
import SaveButton from "../../components/buttons/SaveButton";
import { setIsLoading } from "../common/commonSlice";
import { Breadcrumb } from 'react-bootstrap'; // Utilisez le bon composant ou la bibliothèque de votre choix pour les Breadcrumbs
import { NavLink } from 'react-router-dom';
import ExcelManager from "../../app/managers/excelManager";
import { AddSelectTimesheet } from "../../components/inputs/AddSelectTimesheet";
import { updateTimesheet } from "../../components/scheduler/shifts/shiftsSlice";
import ImageButton from "../../components/buttons/ImageButton";
import { TeamSelector } from "../../components/teams/TeamSelector";
import { ConfirmModal } from '../../components/popups/ConfirmModal';
import { SelectInput } from "../../components/inputs/Select";
import { jsPDF } from "jspdf";
import moment from "moment";
import { generateCraPdf } from "../../components/collabs/collabsSlice";

export function TimesheetView() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const userRoleId = useSelector((state) => state.login.userRoleId)
    const userId = useSelector((state) => state.login.userId)
    const settings = useSelector((state) => state.settings)
    const timesheets = useSelector((state) => state.timesheet.list)
    const hasModifications = useSelector((state) => state.timesheet.hasModifications)
    const selectedTimesheet = useSelector((state) => state.timesheet.selectedTimesheet)
    const selectedTeamsIds = useSelector((state) => state.teams.selectedTeamsIds)
    const selectedPlanning = useSelector((state) => state.timesheet.selectedPlanning)
    const modifiedPlanning = useSelector((state) => state.timesheet.modifiedPlanning)
    const teams = useSelector((state) => state.teams.allTeams)
    const myTeam = useSelector((state) => state.teams.myTeam)
    const groupesList = useSelector((state) => state.groupes.list)
    const timesheetSampleShift = useSelector((state) => state.sampleShifts.timesheetSample)
    const collabs = useSelector((state) => state.collabs)
    const collabReport = useSelector((state) => state.reports.collabReport)
    const [globalFilterValue, setGlobalFilterValue] = useState('')
    const [showConfirmSaveTimesheet, setShowConfirmSaveTimesheet] = useState(false)
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    })
    const isMobile = useSelector((state) => state.settings.isMobile)

    let heureDebut = 9, heureFin = 17, heureMoyenne = 13

    settings.settingsList.map(item => {
        if (item.label === "Heure début de journée") heureDebut = Number(item.settingValue)
        if (item.label === "Heure fin de journée") heureFin = Number(item.settingValue)
        heureMoyenne = (heureFin + heureDebut) / 2
    })

    const toast = useRef(null)
    const dt = useRef(null)
    const mounted = useRef(null)

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true
            if (!timesheets.length)
                dispatch(getTimesheets())
        }
        if (!checkIfEqual(modifiedPlanning, selectedPlanning)) {
            dispatch(setHasModifications(true))
            window.addEventListener('beforeunload', alertUser)
        } else {
            dispatch(setHasModifications(false))
            window.removeEventListener('beforeunload', alertUser)
        }
        return () => {
            window.removeEventListener('beforeunload', alertUser)
        }
    })

    useEffect(() => {
        let yearMonth = urlParams.subView
        if (yearMonth) {
            dispatch(setSelectedTimesheet(yearMonth))
        } else {
            dispatch(setSelectedTimesheet(null))
        }
    }, [dispatch, timesheets, urlParams])

    useEffect(() => {
        if (selectedTimesheet) {
            if (!hasModifications)
                loadTmesheetData()
        } else {
            dispatch(setSelectedPlanning([]))
            dispatch(setModifiedPlanning([]))
        }
    }, [dispatch, selectedTimesheet])

    const alertUser = e => {
        e.preventDefault()
        e.returnValue = ''
    }

    function getFromDayPeriodOptions() {
        let options = [
            { value: heureDebut, label: <Langs str="Matin" /> },
            { value: heureMoyenne, label: <Langs str="Midi" /> },
        ]
        return options
    }

    function getToDayPeriodOptions() {
        let options = [
            { value: heureMoyenne, label: <Langs str="Midi" /> },
            { value: heureFin, label: <Langs str="Soir" /> },
        ]
        return options
    }

    function getSelectedDayPeriod(dateTime) {
        let date = new Date(dateTime)
        let hour = date.getHours()
        if (hour < heureMoyenne) return heureDebut
        else if (hour > heureMoyenne) return heureFin
        return heureMoyenne
    }

    function loadTmesheetData() {
        let yearMonth = urlParams.subView
        if (userId && myTeam && selectedTimesheet && yearMonth) {
            let planning = {
                from: selectedTimesheet.from,
                to: selectedTimesheet.to,
                id_collab: userId,
                id_team: myTeam.id
            }
            dispatch(getCollabPlanning(planning))
        }
    }

    const handleDateChange = (time, id, key) => {
        let planning = []
        modifiedPlanning.map(s => {
            let newShift = Object.assign({}, s)
            if (newShift.id == id) {
                newShift[key] = setAndReturnTimeForDate(s.date, time)
            }
            planning.push(newShift)
        })
        dispatch(setModifiedPlanning(planning))
    }

    const statusBodyTemplate = (rowData) => {
        return <Tag
            value={<Langs str={rowData.status} />}
            severity={getStatusTagClass(rowData.status)}>
        </Tag>
    }

    function handlePlanningChange(id, key, value) {
        let planning = []
        modifiedPlanning.map(s => {
            let newShift = Object.assign({}, s)
            if (newShift.id == id) {
                newShift[key] = value
            }
            planning.push(newShift)
        })
        dispatch(setModifiedPlanning(planning))
    }

    function isDisabled(rowData){
        // temporary give access to AT collabs to modify their timesheets
        return false
        return (!isAdmin(userRoleId) && rowData.status !== 'PENDING')
    }

    const normalDayCheckTemplate = (rowData) => {
        return <Checkbox
            disabled={isDisabled(rowData)}
            onChange={e => handleNormalDayCheckChange(rowData.id, e.checked)}
            checked={checkIfNormalDay(rowData)} />
    }

    function checkIfNormalDay(rowData) {
        return !isEmpty(rowData.id_sample_shift)
    }

    function checkIfRowDisabled(rowData) {
        return isDisabled(rowData) || !checkIfNormalDay(rowData)
    }

    const onFromDayPeriodChange = (option, id) => {
        let planning = []
        modifiedPlanning.map(s => {
            let newShift = Object.assign({}, s)
            if (newShift.id == id) {
                newShift.from = setAndReturnTimeForDate(s.date, option.value * 60)
            }
            planning.push(newShift)
        })
        dispatch(setModifiedPlanning(planning))
    }

    const onToDayPeriodChange = (option, id) => {
        let planning = []
        modifiedPlanning.map(s => {
            let newShift = Object.assign({}, s)
            if (newShift.id == id) {
                if (option.value > 12) {
                    newShift.to = setAndReturnTimeForDate(s.date, (option.value * 60))
                } else {
                    newShift.to = setAndReturnTimeForDate(s.date, option.value * 60)
                }
            }
            planning.push(newShift)
        })
        dispatch(setModifiedPlanning(planning))
    }

    function handleNormalDayCheckChange(id, isChecked) {
        let planning = []
        modifiedPlanning.map(s => {
            let newShift = Object.assign({}, s)
            if (newShift.id == id) {
                if (isChecked) {
                    if (!newShift.from) {
                        let timeFrom = heureDebut * 60
                        if (timesheetSampleShift) {
                            timeFrom = timesheetSampleShift.timeFrom
                        }
                        newShift.from = setAndReturnTimeForDate(newShift.date, timeFrom)
                    }
                    if (!newShift.to) {
                        let timeTo = heureFin * 60
                        if (timesheetSampleShift) {
                            timeTo = timesheetSampleShift.timeTo
                        }
                        newShift.to = setAndReturnTimeForDate(newShift.date, timeTo)
                    }
                    if (!newShift.id_team && (myTeam && myTeam.id)) {
                        newShift.id_team = myTeam.id
                    }
                    // 0 is the value of sample shifts id coming from timesheets
                    newShift.id_sample_shift = 0
                }
                else {
                    // set to null if we need set working day shift off
                    newShift.id_sample_shift = null
                }
            }
            planning.push(newShift)
        })
        dispatch(setModifiedPlanning(planning))
    }

    const hoursBodyTemplate = (rowData) => {
        if (rowData.from && rowData.to) {
            let fromDate = new Date(rowData.from)
            let toDate = new Date(rowData.to)
            return ((toDate.getTime() - fromDate.getTime()) / (60 * 60000)).toFixed(1)
        }
        return "-"
    }

    const fromTimeBodyTemplate = (rowData) => {
        if (!rowData.from) return "-"
        if (rowData.type == 's') {
            return <SelectInput
                disabled={checkIfRowDisabled(rowData)}
                options={getFromDayPeriodOptions()}
                value={getSelectedDayPeriod(rowData.from)}
                onChange={(option) => onFromDayPeriodChange(option, rowData.id)}
            />
        } else {
            let fromDate = new Date(rowData.from)
            let toDate = new Date(rowData.to)
            return <TransparentTimePicker
                disabled={checkIfRowDisabled(rowData)}
                max={(toDate.getHours() * 60 + toDate.getMinutes())}
                value={fromDate.getHours() ? (fromDate.getHours() * 60 + fromDate.getMinutes()) : null}
                onChange={(time) => handleDateChange(time, rowData.id, 'from')}
            />
        }
    }

    const toTimeBodyTemplate = (rowData) => {
        if (!rowData.from) return "-"
        if (rowData.type == 's') {
            return <SelectInput
                disabled={checkIfRowDisabled(rowData)}
                options={getToDayPeriodOptions()}
                value={getSelectedDayPeriod(rowData.to)}
                onChange={(option) => onToDayPeriodChange(option, rowData.id)}
            />
        } else {
            let fromDate = new Date(rowData.from)
            let toDate = new Date(rowData.to)
            return <TransparentTimePicker
                disabled={checkIfRowDisabled(rowData)}
                min={(fromDate.getHours() * 60 + fromDate.getMinutes())}
                value={toDate.getHours() ? (toDate.getHours() * 60 + toDate.getMinutes()) : null}
                onChange={(time) => handleDateChange(time, rowData.id, 'to')}
            />
        }
    }

    function getTeamLabelFromId(teamId) {
        let label = ""
        let teamsFiltered = teams && teams.filter(t => t.id == teamId)
        if (teamsFiltered && teamsFiltered[0]) {
            label = teamsFiltered[0].label
        }
        return label
    }

    const clientBodyTemplate = (rowData) => {
        let teamId = rowData.id_team_delegate ? rowData.id_team_delegate : rowData.id_team
        return <Dropdown
            disabled={checkIfRowDisabled(rowData)}
            id={rowData.id}
            value={teamId}
            options={teams}
            onChange={handleClientChange}
        />
    }

    const handleClientChange = (event) => {
        handlePlanningChange(event.target.id, "id_team_delegate", event.target.value)
    }

    function getAvailableShiftTypes() {
        let availableShiftTypes = [
            {
                value: "s",
                label: <Langs str="Journée standard" />,
                roleId: 3
            }
        ].concat(settings.shiftTypes.filter((shiftType) => {
            return userRoleId <= shiftType.roleId
        }))
        return availableShiftTypes
    }

    const typeBodyTemplate = (rowData) => {
        return <Dropdown
            disabled={checkIfRowDisabled(rowData)}
            id={rowData.id}
            value={rowData.type}
            options={getAvailableShiftTypes()}
            onChange={handleTypeChange}
        />
    }
    const handleTypeChange = (event) => {
        let selectedType = event.target.value
        if (selectedType == 's') {
            let planning = []
            modifiedPlanning.map(s => {
                let newShift = Object.assign({}, s)
                if (newShift.id == event.target.id) {
                    newShift.type = selectedType
                    let timeFrom = heureDebut * 60
                    if (timesheetSampleShift) {
                        timeFrom = timesheetSampleShift.timeFrom
                    }
                    newShift.from = setAndReturnTimeForDate(s.date, timeFrom)
                    let timeTo = heureFin * 60
                    if (timesheetSampleShift) {
                        timeTo = timesheetSampleShift.timeTo
                    }
                    newShift.to = setAndReturnTimeForDate(s.date, timeTo)
                }
                planning.push(newShift)
            })
            dispatch(setModifiedPlanning(planning))
        } else {
            handlePlanningChange(event.target.id, "type", selectedType)
        }
    }

    const groupBodyTemplate = (rowData) => {
        let collab = collabs.collaboratorsList.filter((c) => c.id === rowData.id_collab)[0]
        return <Dropdown
            disabled={checkIfRowDisabled(rowData)}
            id={rowData.id}
            value={rowData.id_groupe}
            options={groupesList.filter((groupe) => collab && collab.groupeIds.includes(groupe.id))}
            onChange={handleGroupChange}
        />
    }
    const handleGroupChange = (event) => {
        handlePlanningChange(event.target.id, "id_groupe", event.target.value)
    }

    const commentsBodyTemplate = (rowData) => {
        return <InputTextarea
            disabled={checkIfRowDisabled(rowData)}
            id={rowData.id}
            value={rowData.description || ""}
            onChange={handleDescriptionChange}
            maxLength={200}
            style={{ minWidth: "10rem", maxHeight: "200px", minHeight: "40px", height: "60px" }}
        />
    }

    const handleDescriptionChange = (event) => {
        handlePlanningChange(event.target.id, "description", event.target.value)
    }

    function handleDelete(rowData) {

    }

    const onRowSelect = (event) => {
        let month = event.value["month"]
        navigate(`/timesheet/${selectedTeamsIds[0]}/${month}`)
    }

    const onGlobalFilterChange = (e) => {
        const value = e.target.value
        let _filters = { ...filters }

        _filters['global'].value = value

        setFilters(_filters)
        setGlobalFilterValue(value)
    }

    const header = isMobile == 3 ? null : (
        <Row>
            <Col md={2}>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        type="search"
                        value={globalFilterValue}
                        onChange={onGlobalFilterChange}
                        placeholder="Search..." />
                </span>
            </Col>
            <Col md={9}>
            </Col>
            <Col md={1} className="d-flex justify-content-end">
                {/* 
                <ImageButton
                    image={deleteIcon}
                    imageFocus={deleteIconFocus}
                    tooltip={"Delete"}
                    active={selectedRows && selectedRows.length}
                    width={32} /> */}
            </Col>
        </Row>
    )

    const handleSaveButtonClick = async () => {
        dispatch(setIsLoading(true))
        dispatch(updateTimesheet({
            items: modifiedPlanning,
            id_timesheet: selectedTimesheet.id
        })).then((response) => {
            let error = response["payload"]["responseError"]
            dispatch(setIsLoading(false))
            if (error) {
                showErrorToast()
            } else {
                loadTmesheetData()
            }
        })
    }

    function renderFloatingButton() {
        if (hasModifications) {
            return <SaveButton
                style={{ color: 'white', backgroundColor: '#FA9016', padding: 10 }}
                active={true}
                image={saveIcon}
                onClick={handleSaveButtonClick}
                tooltip="SAVE" />
        }
    }

    // function getSchedulerHeight() {
    //     let heightDiff = 122
    //     if (selectedSamplesView) {
    //         heightDiff += 65
    //     }
    //     return `calc(100vh - ${heightDiff}px)`
    // }


    const exportExcelReport = async () => {
        await ExcelManager.getInstance().generateAndDownloadComptaReport()
    }

    const selectedSamplesViewTimesheet = useSelector(
        (state) => state.timesheet.selectedSamplesViewTimesheet
    )

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="add_shift">
                {isAdmin(userRoleId) ? <AddSelectTimesheet /> : null}
            </div>
        )
    }

    function handleAddRow(rowData, rowDetails) {
        let newShift = {
            id: generateUniqueId(),
            id_collab: rowData.id_collab,
            id_team: rowData.id_team,
            dateTime: rowData.dateTime,
            day: rowData.day,
            date: rowData.date,
            type: 's',
            status: 'PENDING'
        }
        const newPlanning = [
            ...modifiedPlanning.slice(0, rowDetails.rowIndex + 1),
            newShift,
            ...modifiedPlanning.slice(rowDetails.rowIndex + 1)
        ]
        dispatch(setModifiedPlanning(newPlanning))
    }

    const handleNavClick = (e) => {
        if (hasModifications) {
            e.preventDefault()
            setShowConfirmSaveTimesheet(true)
        }
    }

    function handleSaveTimesheet() {
        setShowConfirmSaveTimesheet(false)
        dispatch(setIsLoading(true))
        dispatch(updateTimesheet({
            items: modifiedPlanning,
            id_timesheet: selectedTimesheet.id
        })).then((response) => {
            let error = response["payload"]["responseError"]
            dispatch(setIsLoading(false))
            if (error) {
                showErrorToast()
            } else {
                dispatch(setSelectedTimesheet(null))
                navigate(`/timesheet`)
            }
        })
    }

    function handleDiscardTimesheet() {
        setShowConfirmSaveTimesheet(false)
        dispatch(setSelectedTimesheet(null))
        navigate(`/timesheet`)
    }

    const handleExtractClick = (event) => {

        const monthDate = moment(selectedTimesheet.month, "MMMM YYYY").format("MM-YYYY")
        const fileName = `CRA_${monthDate}.pdf`
        let type = "CRA"
        // Appel de l'action pour générer le PDF avec props.data et CollabReport
        if (myTeam)
            dispatch(generateCraPdf({
                id_collab: userId,
                id_team: myTeam.id,
                monthDate: monthDate,
                fileName: fileName,
                type: type
            }))
    }

    const generatePdf = () => {
        const pdf = new jsPDF({
            orientation: 'landscape', // Spécifier l'orientation comme paysage
            unit: 'mm', // Spécifier l'unité de mesure
            format: 'a4', // Spécifier le format du papier
        })

        // Ajouter un en-tête
        const headerText = selectedTimesheet.month
        pdf.text(headerText, 14, 15)

        //Calculer la hauteur de l'en-tête
        const headerHeight = pdf.getTextDimensions(headerText).h

        // Tableau 1
        const table1 = document.querySelector("#activity-table .p-datatable-table")
        pdf.autoTable({ html: table1, startY: headerHeight + 20 })

        const month = selectedTimesheet.month

        const fileName = `Activity-${month}.pdf`
        pdf.save(fileName)
    }

    const rowClass = (data) => {
        let className = {}
        let date = moment(data.dateTime, "x")

        let weekDay = date.day()
        if (weekDay == 0) className = {
            'bg-sunday': true
        }
        else if (weekDay == 6) className = {
            'bg-saturday': true
        }
        else {
            let formattedDate = date.format("DD-MM-YYYY")
            if (settings.holidays[date.year()])
                for (let i = 0; i < settings.holidays[date.year()].length; i++) {
                    const holiday = settings.holidays[date.year()][i]
                    if (formattedDate === holiday.date)
                        className = {
                            'bg-holiday': true
                        }
                }
        }
        return className
    }

    let columnsStyle = {
        paddingTop: '5px',
        paddingBottom: '5px'
    }

    return (
        <div className={styles.containerView}>
            <div className="topMenu" style={isMobile == 3 ? { height: 'auto' } : {}} >
                <Row>
                    <Col md={isMobile == 3 ? 8 : 6} xs={12}>
                        <span className="headerTitle">
                            {<Langs str={"Timesheet"} />}
                        </span>
                        <TeamSelector onlyMyTeams />

                    </Col>
                    {isMobile == 3 && selectedTimesheet && (
                        <Col md={4} >
                            <Breadcrumb className={styles.breadcrumb} >
                                <Breadcrumb.Item className={styles.breadcrumb}>
                                    <NavLink to="/timesheet" onClick={handleNavClick}>
                                        <Langs str="Timesheet" />
                                    </NavLink>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>{selectedTimesheet.month}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    )}
                    {isMobile == 2 && selectedTimesheet && (
                        <Col xs={6} md={3}>
                            <Breadcrumb className={styles.breadcrumb} >
                                <Breadcrumb.Item className={styles.breadcrumb}>
                                    <NavLink to="/timesheet" onClick={handleNavClick}>
                                        <Langs str="Timesheet" />
                                    </NavLink>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>{selectedTimesheet.month}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    )}
                    <Col xs={6} md={(isMobile == 2 || isMobile == 3) && selectedTimesheet ? 4 : 6} className="d-flex justify-content-end align-items-center p-0 m-0 pe-2">
                        {selectedTimesheet && <ImageButton
                            image={exportIcon}
                            imageFocus={exportIconFocus}
                            onClick={handleExtractClick}
                            active={true}
                            tooltip="Generate CRA PDF"
                            width={32} />}
                    </Col>
                </Row>
            </div>
            {selectedTimesheet && isMobile == 1 && <Breadcrumb>
                <Breadcrumb.Item>
                    <NavLink to="/timesheet" onClick={handleNavClick} >
                        <Langs str="Timesheet" />
                    </NavLink>
                </Breadcrumb.Item>
                {selectedTimesheet && <Breadcrumb.Item active>{selectedTimesheet.month}</Breadcrumb.Item>}
            </Breadcrumb>
            }
            <div>
                {selectedTimesheet ?
                    <div className={styles.dailyCard}>
                        <DataTable ref={dt}
                            id="activity-table"
                            value={modifiedPlanning}
                            onSelectionChange={(e) => onRowSelect(e)}
                            dataKey="id"
                            scrollable
                            scrollHeight={isMobile == 3 ? "calc(100vh - 130px)" : isMobile == 2 ? "calc(100vh - 200px)" : "calc(100vh - 185px)"}
                            rowClassName={rowClass}>
                            <Column field="day" header={<Langs str="Day" />} style={{ ...columnsStyle, minWidth: '8rem' }}></Column>
                            <Column field="date" header={<Langs str="Date" />} style={{ ...columnsStyle, minWidth: '10rem' }}></Column>
                            <Column header={<Langs str="Work day" />} body={normalDayCheckTemplate} style={{ ...columnsStyle, minWidth: '7rem' }}></Column>
                            <Column field="type" header={<Langs str="Type" />} body={typeBodyTemplate} style={{ ...columnsStyle, minWidth: '10rem' }}></Column>
                            <Column field="start" header={<Langs str="Start" />} body={fromTimeBodyTemplate} style={{ ...columnsStyle, minWidth: '10rem' }}></Column>
                            <Column field="end" header={<Langs str="End" />} body={toTimeBodyTemplate} style={{ ...columnsStyle, minWidth: '10rem' }}></Column>
                            <Column field="hours" header={<Langs str="Hours" />} body={hoursBodyTemplate} style={{ ...columnsStyle, minWidth: '7rem' }}></Column>
                            <Column field="client" header={<Langs str="Client" />} body={clientBodyTemplate} style={{ ...columnsStyle, minWidth: '10rem' }}></Column>
                            <Column field="group" header={<Langs str="Group" />} body={groupBodyTemplate} style={{ ...columnsStyle, minWidth: '10rem' }}></Column>
                            <Column field="comments" header={<Langs str="Comments" />} body={commentsBodyTemplate} style={{ ...columnsStyle, minWidth: '10rem' }}></Column>
                            <Column
                                body={(rowData, rowIndex) => (
                                    <ImageButton
                                        tooltip={"ADD_SHIFT"}
                                        onClick={() => handleAddRow(rowData, rowIndex)}
                                        image={addIcon}
                                        imageFocus={addIconFocus}
                                        active={true}
                                        width={32}
                                    />
                                )}
                                style={{ ...columnsStyle, minWidth: '2rem', textAlign: 'center' }}
                                frozen
                                alignFrozen="right"
                            ></Column>
                        </DataTable>
                    </div>
                    :
                    <div className={styles.monthlyCard}>
                        <DataTable ref={dt}
                            value={timesheets}
                            onSelectionChange={(e) => onRowSelect(e)}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            scrollable
                            scrollHeight={isMobile == 3 ? "calc(100vh - 200px)" : isMobile == 2 ? "calc(100vh - 350px)" : "calc(100vh - 315px)"}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate={"Showing {first} to {last} of {totalRecords}"}
                            header={header}
                            filters={filters}
                            selectionMode="single">
                            <Column field="month" header={<Langs str="Month" />} sortable style={{ minWidth: '8rem' }}></Column>
                            <Column field="start" header={<Langs str="Start" />} sortable style={{ minWidth: '10rem' }}></Column>
                            <Column field="end" header={<Langs str="End" />} sortable style={{ minWidth: '10rem' }}></Column>
                            <Column field="updated_at" header={<Langs str="Last updated" />} sortable style={{ minWidth: '10rem' }}></Column>
                            <Column field="status" header={<Langs str="Status" />} body={statusBodyTemplate} sortable style={{ minWidth: '8rem' }}></Column>
                            {/* <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '6rem', textAlign: "right" }}></Column> */}
                        </DataTable>
                    </div>
                }
            </div>

            <ConfirmModal show={showConfirmSaveTimesheet}
                title={<Langs str='SAVE_TIMESHEET' />}
                confirmTitle={<Langs str='SAVE' />}
                denyTitle={<Langs str='DISCARD' />}
                message={<Langs str='SAVE_TIMESHEET_MSG' />}
                onConfirm={() => handleSaveTimesheet()}
                onHide={() => setShowConfirmSaveTimesheet(false)}
                onDeny={() => handleDiscardTimesheet()} />

            {renderFloatingButton()}
        </div>
    )
}
